import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import default_company_logo from "../../assets/images/d-company-logo.png";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import cloudOutline from "../../assets/images/cloud-outline.png";
import ApiService from "../../config/restapi";
import Select from "react-select";
import { Domains } from "../../helpers/constant_variables";
import s3apis from "../../helpers/s3apis";
import Common from "../../helpers/common";

const CompnaySizes = [
  {
    value: 1,
    label: "0-10 employees",
    modifyName: "Start up/ Small Enterprise",
  },
  {
    value: 2,
    label: "10-50 employees",
    modifyName: "Mid sized",
  },
  {
    value: 3,
    label: "50-200+ employees",
    modifyName: "Large scale/ Global",
  },
];

const EditProfileModal = (props) => {
  const [country, setCountry] = useState("IN");
  const [fname, setFName] = useState();
  const [industry, setIndustry] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [size, setSize] = useState();
  const [location, setLocation] = useState();
  const [url, setUrl] = useState();
  const [profilepic, setProfilepic] = useState(null);
  const [companySizes, setCompanySizes] = useState(CompnaySizes);
  const [isdCode, setIsdCode] = useState();

  function checkUrl(url) {
    if (url === undefined || url === "") {
      return { valid: true, message: "" };
    }
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (!regexp.test(url)) {
      return { valid: false, message: "Enter a valid URL" };
    } else {
      return { valid: true, message: "" };
    }
  }
  function checkEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid email address", valid: false };
  }

  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }
  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (
      ext &&
      (ext.toLowerCase() === "jpeg" ||
        ext.toLowerCase() === "png" ||
        ext.toLowerCase() === "gif" ||
        ext.toLowerCase() === "jpg")
    ) {
      return true;
    }
    return false;
  }

  const handleAcceptedFiles = async (files) => {
    if (validateFileExtention(files)) {
      console.log(files[0]);
      var ext = files[0].name.split(".").pop();
      if (files[0].size < 5242880) {
        const url = await s3apis.upload("logo", ext, files[0]);
        OnchangeHandler(url, "logo_url");
      } else {
        toastr.error("Select a file of size less than 5 MB", "", {
          timeOut: 2000,
        });
      }
    } else {
      toastr.error("Please Upload Valid Photo", "", { timeOut: 2000 });
    }
  };

  function clearData() {}

  const removeProfilePic = () => {
    setProfilepic(null);
  };

  const OnchangeHandler = (value, type) => {
    switch (type) {
      case "fname":
        value = Common.trimStart(value.replace(/[^\w\s]/gi, ""));
        setFName(value);
        break;
      case "email":
        value = Common.trimStart(value.replace(/[^\w\s\.@]/gi, ""));
        setEmail(value);
        break;
      case "mobileNumber":
        value.replace(/[^\w\s\.\,]/gi, "");
        setMobileNumber(value);
        break;
      case "size":
        setSize(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "url":
        value = Common.trimStart(value);
        setUrl(value);
        break;
      case "industry":
        setIndustry(value);
        break;
      case "isd_code":
        setIsdCode(value);
        break;
      case "logo_url":
        setProfilepic(value);
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.editedData) {
      setFName(props.editedData.name);
      setEmail(props.editedData.email);
      setMobileNumber(props.editedData.number);
      setProfilepic(props.editedData.logo_url);
      setSize(props.editedData.size);
      setLocation(props.editedData.location);
      setUrl(props.editedData.URL);
      setIndustry({ label: props.editedData.industry });
      makeCompanySizeSelected(props.editedData.size);
      setIsdCode(props.editedData.isd_code);
    }
  }, [props.editedData]);

  function makeCompanySizeSelected(size) {
    let tempSizes = [...companySizes];
    tempSizes.map((item) => {
      if (item.label === size) {
        item["selected"] = "selected";
      }
    });
  }

  function companySizeSelect(item, index) {
    let tempData = [...companySizes];
    tempData.map((item, idx) => {
      if (index === idx) {
        item["selected"] = "selected";
        setSize(item.label);
      } else {
        delete item.selected;
      }
    });
    setCompanySizes([...tempData]);
  }
  const savechanges = () => {
    props.onModalSubmit(
      {
        name: fname,
        email,
        number: mobileNumber,
        size,
        industry: industry.label,
        location,
        URL: url,
        logo_url: profilepic,
        id: props.editedData.id,
        isd_code: isdCode,
      },
      "edit"
    );
    clearData();
  };
  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      size="lg"
      className="edit-profile-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          Edit Company Profile
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Company Logo</Label>
              <div className="d-flex">
                <div className="profile-logo-container">
                  <img
                    src={profilepic ? profilepic : default_company_logo}
                    alt=""
                    style={{ background: "#fff" }}
                    className="avatar-md img-thumbnail rounded-circle"
                  />
                </div>
                <div className="upload-section">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span className="font-size-12">
                            <button
                              className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                              type="button"
                            >
                              <img src={cloudOutline} alt="cloud" />{" "}
                              <span className="pl-1">UPLOAD PHOTO</span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {profilepic && (
                    <div className="pt-1 pl-1">
                      <button
                        className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                        type="button"
                        onClick={() => removeProfilePic()}
                      >
                        <i className="mdi mdi-delete-outline font-size-14 cursor-pointer label-required" />
                        <span className="pl-1 label-required">
                          REMOVE PHOTO{" "}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">Company Name</Label>
              <Input
                type="text"
                maxLength="40"
                value={fname || ""}
                disabled={true}
                onChange={(e) => OnchangeHandler(e.target.value, "fname")}
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">Email Address </Label>
              <Input
                type="email"
                value={email || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "email")}
              />
              {email != undefined && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {" "}
                  {checkEmail(email).message}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">Phone Number </Label>
              <Row>
                <Col lg="3" xs="3" className="pr-0">
                  <select
                    className="form-control phone-number-select"
                    value={isdCode || ""}
                    onChange={(event) => {
                      OnchangeHandler(event.target.value, "isd_code");
                    }}
                  >
                    {getCountries().map((country) => (
                      <option key={country} value={country}>
                        {country} {getCountryCallingCode(country)}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg="9" xs="9">
                  {/* <PhoneInput
                    country={country ? country : "IN"}
                    international
                    value={mobileNumber}
                    onChange={(v) => OnchangeHandler(v, "mobileNumber")}
                    className="form-control phone-number-input"
                  /> */}
                  <Input
                    type="tel"
                    maxLength={10}
                    value={mobileNumber}
                    onChange={(e) =>
                      OnchangeHandler(e.target.value, "mobileNumber")
                    }
                  />
                </Col>
                {mobileNumber != undefined && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    {checkmobileNumber(mobileNumber).message}
                  </label>
                )}
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">Company Size </Label>
              <div className="d-flex company-size">
                {companySizes.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`green-status-text cursor-pointer ${item.selected}`}
                      onClick={() => companySizeSelect(item, index)}
                    >
                      <p className="mb-0"> {item.modifyName} </p>
                      <div>{item.label}</div>
                    </div>
                  );
                })}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">Industry </Label>
              {/* <Input
                type="text"
                value={industry || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "industry")}
              /> */}
              <Select
                className="select-container"
                value={industry || ""}
                onChange={(selectedOption) =>
                  OnchangeHandler(selectedOption, "industry")
                }
                options={Domains}
                isSearchable
                innerProps={{ className: "test" }}
                placeholder="Select"
              />
              {industry !== undefined && !industry && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Select an industry type from the list
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Company Location(s)
              </Label>
              {/* <Input
                type="text"
                value={location || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "location")}
              /> */}
              <Input
                type="select"
                value={location || ""}
                onChange={(event) =>
                  OnchangeHandler(event.target.value, "location")
                }
                name="select"
                id="exampleCity"
              >
                <option>Select</option>
                <option>Mumbai</option>
                <option>Delhi</option>
                <option>Bangalore</option>
                <option>Hyderabad</option>
                <option>Ahmedabad</option>
                <option>Chennai</option>
                <option>Kolkata</option>
                <option>Surat</option>
                <option>Pune</option>
                <option>Jaipur</option>
                <option>Lucknow</option>
                <option>Kanpur</option>
                <option>Nagpur</option>
                <option>Indore</option>
                <option>Thane</option>
                <option>Bhopal</option>
                <option>Visakhapatnam</option>
                <option>Pimpri-Chinchwad</option>
                <option>Patna</option>
                <option>Vadodara</option>
                <option>Ghaziabad</option>
                <option>Ludhiana</option>
                <option>Agra</option>
                <option>Nashik</option>
                <option>Faridabad</option>
                <option>Meerut</option>
                <option>Rajkot</option>
                <option>Kalyan-Dombivali</option>
                <option>Vasai-Vira</option>
                <option>Varanasi</option>
                <option>Srinagar</option>
                <option>Aurangabad</option>
                <option>Dhanbad</option>
                <option>Amritsar</option>
                <option>Navi Mumbai</option>
                <option>Allahabad</option>
                <option>Ranchi</option>
                <option>Howrah</option>
                <option>Coimbatore</option>
                <option>Jabalpur</option>
                <option>Gwalior</option>
                <option>Vijayawada</option>
                <option>Jodhpur</option>
                <option>Madurai</option>
                <option>Raipur</option>
                <option>Kota</option>
              </Input>
              {location !== undefined &&
                (location === "" || location === "Select") && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    Select a location from the list
                  </label>
                )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Website URL{" "}
                <span className="font-size-11 text-muted font-weight-ligt">
                  (Optional)
                </span>
              </Label>
              <Input
                type="text"
                value={url || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "url")}
              />
              {url !== undefined && url && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkUrl(url).message}
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          className={`btn bg-green waves-effect waves-light`}
          disabled={
            !location ||
            !checkmobileNumber(mobileNumber).valid ||
            !checkEmail(email).valid ||
            !industry ||
            !checkUrl(url).valid
          }
          onClick={() => {
            if (
              !location ||
              !checkmobileNumber(mobileNumber).valid ||
              !checkEmail(email).valid ||
              !industry ||
              !checkUrl(url).valid
            ) {
              if (email === undefined) setEmail("");
              if (mobileNumber === undefined) setMobileNumber("");
              if (industry === undefined) setIndustry("");
              if (location === undefined) setLocation("");
              return;
            }
            savechanges();
          }}
        >
          Save Changes{" "}
        </button>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
