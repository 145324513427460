import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import phone_icon from "../../assets/images/phone_icon.png";
import email_icon from "../../assets/images/email_icon.png";
import company_img from "../../assets/images/company.png";
import EditProfileModal from "./EditProfileModal";
import AboutCompanyModal from "./AboutCompanyModal";
import ApiService from "../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { getOrganizationDetails } from "../../redux/actions/profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import default_company_logo from '../../assets/images/d-company-logo.png';

const CompnaySizes = [
  {
    value: 1,
    label: "0-10 employees",
    modifyName: "Start up/ Small Enterprise",
  },
  {
    value: 2,
    label: "10-50 employees",
    modifyName: "Mid sized",
  },
  {
    value: 3,
    label: "50-200+ employees",
    modifyName: "Large scale/ Global",
  },
];

const Profile = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [modal_about, setmodal_about] = useState(false);
  const history = useHistory();

  useEffect(() => {
    props.getOrganizationDetails();
  }, []);

  function tog_about() {
    setmodal_about(!modal_about);
    removeBodyCss();
  }

  const openModalAbout = () => {
    tog_about();
  };

  function updateAboutInfo() { }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const openModal = () => {
    tog_center();
  };

  const updateProfileData = async (data, type, modal) => {
    if (type === "edit") {
      ApiService.editProfileData(data).then((res) => {
        if (res && res.status === 200 && res.data) {
          // props.getOrganizationDetails();
          history.go(0);
          if (!props.error) {
            if (modal === "about") {
              tog_about(false);
            } else {
              tog_center(false);
            }
            console.log(props);
            toastr.success("Profile Updated Successfully", "", {
              timeOut: 2000,
            });
          }
        }
      });
    }
  };

  function getModifyName(name) {
    let temModifyName = "";
    CompnaySizes.map((item) => {
      if (item.label === name) {
        temModifyName = item.modifyName;
      }
    });
    return temModifyName;
  }

  return (
    <React.Fragment>
      <div className="profile-page my-5 pt-sm-5">
        <Container fluid>
          <Row>
            <Col xl="12" className="pl-0 pr-0 mobile_padding">
              <div className="top-container">
                <div className="ux-designer"> {props.profile.name}</div>
                <div>
                  <img
                    className="ml-4 shift-image rounded-circle avatar-md"
                    src={
                      props.profile.logo_url ? props.profile.logo_url : default_company_logo
                    }
                    style={{ background: "#fff" }}

                    alt="Header Avatar"
                  />
                </div>
                <div className="d-flex justify-content-between company-info">
                  <div className="d-flex mx-5 px-4 green-text-color mobile_profile_header">
                    <div className="mr-3">
                      <div className="web_display">
                        <div className="f-14 mb-2 px-2 mob_display">
                          <img src={email_icon} alt="email" />
                          <span className="ml-2">{props.profile.email ? props.profile.email : '--'}</span>
                        </div>
                        <div className="ml-2 mob_display">
                          <img src={phone_icon} alt="email" />
                          <span className="ml-2">{props.profile.number ? props.profile.number : '--'}</span>
                        </div>
                      </div>

                      <div className="web_display">
                        <div className="f-14 mb-2 px-2 mob_display mobile_top_spacing">
                          {/* <img src={company_img} alt="company" /> */}
                          {props.profile.location && <i className="fas fa-map-marker-alt mb-2 px-2"></i>}
                          {props.profile.location && <span className="">{props.profile.location}</span>}
                        </div>
                        <div className="ml-2 mob_display">
                          {props.profile.size && <span className="ml-4 green-status-text mobile_no_left_margin">
                            {" "}
                            {props.profile.size
                              ? getModifyName(props.profile.size)
                              : "--"}
                          </span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start position-relative mobile_pencil_icon">
                  <span className="right-pencil-section">
                    <i
                      className="bx bx-pencil font-size-18 cursor-pointer"
                      onClick={() => openModal()}
                    ></i>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="about-section">
            <Col xl="12" className="p-0">
              <div className="d-flex justify-content-between">
                <p>About the company</p>
                <i
                  className="bx bx-pencil font-size-18 cursor-pointer"
                  onClick={() => openModalAbout()}
                ></i>
              </div>
              <div className="mt-2">{props.profile.description}</div>
            </Col>
          </Row>
        </Container>
        <EditProfileModal
          modal_center={modal_center}
          centered={true}
          tog_center={() => tog_center()}
          onModalSubmit={updateProfileData}
          editedData={props.profile}
        />
        <AboutCompanyModal
          modal_center={modal_about}
          centered={true}
          tog_center={() => tog_about()}
          onModalSubmit={updateProfileData}
          editedData={props.profile}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrganizationDetails }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
