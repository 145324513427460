import React, { useState, useEffect } from "react";
import { Card, CardBody, Form } from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import cloudOutline from '../../assets/images/cloud-outline.png';


const UploadFile = (props) => {
  const [selectedFiles, setselectedFiles] = useState([]);

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      return true;
    }
    return false;
  }

  async function handleAcceptedFiles(files) {
    console.log(files);
    if (validateFileExtention(files)) {
      if (files[0].size < 5242880) {
        await setselectedFiles(files);
        props.selectedFilesCallBack && props.selectedFilesCallBack(files);
      }
      else {
        toastr.error("Select a file of size less than 5 MB", "", { timeOut: 2000 });
      }
    } else {
      toastr.error("Please Upload Valid Resume Format", "", { timeOut: 2000 });
    }
  }

  const files = selectedFiles.map(file => (
    <span key={file.name}>
      {file.name}
    </span>
  ));

  return (
    <div className="upload-file">
      <Dropzone
        onDrop={(acceptedFiles) => {
          handleAcceptedFiles(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => {
          console.log(getRootProps())
          console.log(getInputProps())
          return (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                  <div> <img src={cloudOutline} alt='cloud' /> </div>
                  <div className="font-size-14 mt-2">
                    {/* <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i> */}
                    Drag and drop file here (doc and pdf are aceepted)
                  </div>
                  <div className="mt-2 font-size-11">Or</div>
                  <div className="upload-resume-text">
                    <button className="btn bg-green waves-effect waves-light w-md mr-2" type="button">
                      {props.label ? props.label : "Upload Resume"}
                    </button>
                  </div>
                </div>
              </div>
              <aside>
                <p>{files}</p>
              </aside>
            </div>
          )
        }}
      </Dropzone>
    </div>
  );
};

export default UploadFile;
