//import api from '../../config/axios';
import {
  PROFILE_DETAILS,
  PROFILE_DETAILS_ERROR,
  PROFILE_DETAILS_LOADING,
} from "../profile";
import axios from "axios";
const API_END_POINT = "https://api.zunavish.com/api";
const headers = {
  "Content-Type": "application/json",
  headers: {
    "Access-Control-Allow-Origin": "*",
    token: `${localStorage.getItem("token")}`,
  },
};

export const getOrganizationDetails = () => async (dispatch) => {
  dispatch({
    type: PROFILE_DETAILS_LOADING,
  });
  try {
    const response = await axios.get(
      API_END_POINT + "/organizations/",
      headers
    );
    if (response.status === 200) {
      dispatch({
        type: PROFILE_DETAILS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: PROFILE_DETAILS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: PROFILE_DETAILS_ERROR,
    });
  }
};
