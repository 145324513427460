import React, { useEffect, useState } from "react";
import { NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import CompanyDetails from "./CompanyDetails";
import AboutCompany from "./AboutCompany";
import ContactInfo from "./ContactInfo";
import { withRouter } from "react-router-dom";
import ApiService from "../../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const CompanyWizard = (props) => {
  const [companyDetailObj, setCompanyDetailObj] = useState({});

  const wizardHeadersNames = [
    { name: "Company Details", key: "pd-width" },
    { name: "About the company", key: "ed-width" },
    { name: "Contact Information", key: "empd-width" },
  ];

  const [wizardHeaders, setwizardHeaders] = useState([]);
  const [activeTab, setactiveTab] = useState(1);
  function checkUrl(url) {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (!regexp.test(url)) {
      return { valid: false, message: "Enter a valid URL" };
    } else {
      return { valid: true, message: "" };
    }
  }
  function checkEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid email address", valid: false };
  }

  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  const OnchangeCallBack = (value, type) => {
    switch (type) {
      case "name":
        value = value.replace(/[^a-zA-Z\s]/gi, "").trimStart();
        setCompanyDetailObj({
          ...companyDetailObj,
          name: value,
        });
        break;
      case "websiteurl":
        setCompanyDetailObj({
          ...companyDetailObj,
          website_url: value,
        });
        break;
      case "company_logo":
        setCompanyDetailObj({
          ...companyDetailObj,
          logo_url: value,
        });
        break;
      case "size":
        setCompanyDetailObj({
          ...companyDetailObj,
          size: value.label,
          selectedOpt: value,
        });
        break;
      case "industry":
        setCompanyDetailObj({
          ...companyDetailObj,
          industry: value.label,
          industryObj: value,
        });
        break;
      case "description":
        value = value.replace(/[^\w\s\.\,]/gi, "").trimStart();

        setCompanyDetailObj({
          ...companyDetailObj,
          description: value,
        });
        break;
      case "location":
        setCompanyDetailObj({
          ...companyDetailObj,
          location: value,
        });
        break;
      case "email":
        value = value.replace(/[^\w\s\.\,@]/gi, "").trimStart();
        setCompanyDetailObj({
          ...companyDetailObj,
          email: value,
        });
        break;
      case "mobile":
        value = value.replace(/[^0-9]/gi, "").trimStart();

        setCompanyDetailObj({
          ...companyDetailObj,
          number: value,
        });
        break;
      case "isd_code":
        setCompanyDetailObj({
          ...companyDetailObj,
          isd_code: value,
        });
        break;
      default:
        break;
    }
  };

  const onNextClickEduDataCallBack = (eduData, rowData, editIndex) => {};

  const onNextClickEmploymentCallBack = (empData, rowData, editIndex) => {};

  const onSkipClick = (tab) => {
    if (tab === 1) {
      if (
        !checkUrl(companyDetailObj.website_url).valid &&
        companyDetailObj.website_url !== undefined
      ) {
        console.log(companyDetailObj.website_url);
        return;
      }
      setactiveTab(2);
    } else if (tab === 2) {
      if (
        !companyDetailObj.size ||
        !companyDetailObj.industryObj ||
        !companyDetailObj.location
      ) {
        let tempobj = {};
        if (!companyDetailObj.size || companyDetailObj.size === undefined)
          tempobj.size = "";
        if (
          !companyDetailObj.industryObj ||
          companyDetailObj.industryObj === undefined
        )
          tempobj.industryObj = "";
        if (
          !companyDetailObj.location ||
          companyDetailObj.location === undefined
        )
          tempobj.location = "";
        setCompanyDetailObj({
          ...companyDetailObj,
          ...tempobj,
        });
        return;
      }
      console.log("about company", companyDetailObj);
      setactiveTab(3);
    } else if (tab === 3) {
      if (
        !checkEmail(companyDetailObj.email).valid ||
        !checkmobileNumber(companyDetailObj.number).valid
      ) {
        let tempobj = {};
        if (!companyDetailObj.email || companyDetailObj.email === undefined)
          tempobj.email = "";
        if (!companyDetailObj.number || companyDetailObj.number === undefined)
          tempobj.number = "";
        setCompanyDetailObj({
          ...companyDetailObj,
          ...tempobj,
        });
        return;
      }
      let obj = {
        ...companyDetailObj,
        URL: companyDetailObj.website_url,
      };
      props.onCompleteAboutYourselfCallBack();
    }
  };

  const onNextClick = (tab) => {
    if (tab === 1) {
      if (
        !checkUrl(companyDetailObj.website_url).valid &&
        companyDetailObj.website_url !== undefined
      ) {
        console.log(companyDetailObj.website_url);
        return;
      }
      setactiveTab(2);
    } else if (tab === 2) {
      if (
        !companyDetailObj.size ||
        !companyDetailObj.industryObj ||
        !companyDetailObj.location
      ) {
        let tempobj = {};
        if (!companyDetailObj.size || companyDetailObj.size === undefined)
          tempobj.size = "";
        if (
          !companyDetailObj.industryObj ||
          companyDetailObj.industryObj === undefined
        )
          tempobj.industryObj = "";
        if (
          !companyDetailObj.location ||
          companyDetailObj.location === undefined
        )
          tempobj.location = "";
        setCompanyDetailObj({
          ...companyDetailObj,
          ...tempobj,
        });
        return;
      }
      console.log("about company", companyDetailObj);
      setactiveTab(3);
    } else if (tab === 3) {
      if (
        !checkEmail(companyDetailObj.email).valid ||
        !checkmobileNumber(companyDetailObj.number).valid
      ) {
        let tempobj = {};
        if (!companyDetailObj.email || companyDetailObj.email === undefined)
          tempobj.email = "";
        if (!companyDetailObj.number || companyDetailObj.number === undefined)
          tempobj.number = "";
        setCompanyDetailObj({
          ...companyDetailObj,
          ...tempobj,
        });
        return;
      }
      let obj = {
        ...companyDetailObj,
        URL: companyDetailObj.website_url,
      };

      ApiService.editProfileData(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          props.onCompleteAboutYourselfCallBack();
          props.history.push("/jobdashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    }
  };

  useEffect(() => {
    ApiService.getProfileDetails().then((res) => {
      if (res && res.status === 200 && res.data) {
        setCompanyDetailObj({
          ...companyDetailObj,
          name: res.data.name,
        });
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }, []);

  useEffect(() => {
    wizardHeadersNames.map((obj, index) => {
      if (activeTab === index + 1) {
        obj["active"] = "active";
        obj["key"] = wizardHeadersNames[index].key;
      } else {
        delete obj.active;
        delete obj.key;
      }
      if (activeTab === 2 || activeTab === 3) {
        if (activeTab === 3)
          wizardHeadersNames[activeTab - 3]["makeactive"] = activeTab - 3;
        wizardHeadersNames[activeTab - 2]["makeactive"] = activeTab - 2;
        wizardHeadersNames[activeTab - 2]["lessWidthD"] = "less-width-d";
      }
    });
    setwizardHeaders([...wizardHeadersNames]);
  }, [activeTab]);

  const ToggleHeaderTab = (tab, item) => {
    if ("makeactive" in item) {
      setactiveTab(item.makeactive + 1);
    }
  };

  const WizardHeaders = (item1, idx) => {
    return (
      <NavItem key={idx}>
        <NavLink className={`text-center ${item1.active ? item1.active : ""}`}>
          <span
            onClick={() => {
              ToggleHeaderTab(activeTab, item1);
            }}
            className={classnames(
              "step-number mr-1",
              { [`${item1.key}`]: item1.key },
              { [`${item1.lessWidthD}`]: item1.lessWidthD },
              { [`${item1.solidImage}`]: item1.solidImage }
            )}
          >
            {idx + 1}
          </span>
          {item1.name ? (
            <div className={classnames("font-size-12 mt-2")}>
              {" "}
              {item1.name}{" "}
            </div>
          ) : (
            ""
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div id="basic-pills-wizard" className="twitter-bs-wizard company-wizard">
      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
        {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
      </ul>
      <Card>
        <CardBody>
          {activeTab === 1 ? (
            <CompanyDetails
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              details={companyDetailObj}
            />
          ) : activeTab === 2 ? (
            <AboutCompany
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              onNextClickEduDataCallBack={onNextClickEduDataCallBack}
              details={companyDetailObj}
            />
          ) : (
            <ContactInfo
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              details={companyDetailObj}
              onNextClickEmploymentCallBack={onNextClickEmploymentCallBack}
            />
          )}
        </CardBody>
      </Card>
      <div className="text-right">
        {activeTab !== 3 && (
          <button
            className={classnames(
              "btn font-light-green btn-outline-green waves-effect waves-light w-lg mr-2"
            )}
            type="submit"
            onClick={() => onSkipClick(activeTab)}
          >
            Skip
          </button>
        )}
        <button
          className="btn bg-green waves-effect waves-light w-lg"
          type="submit"
          onClick={() => onNextClick(activeTab)}
        >
          {activeTab === 3 ? "Start Exploring" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default withRouter(CompanyWizard);
