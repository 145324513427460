import React from "react";
import { Redirect } from "react-router-dom";

//Components
import JobWizard from "../pages/Authentication/JobAndAssesment";
import JobDetails from "../pages/JobDetails";
import JobDashboard from '../pages/JobDashboard';
import SetPassword from "../pages/Authentication/SetPassword";
import Login from "../pages/Authentication/Login";
import Profile from '../pages/Profile';
import AccountSetting from '../pages/AccountSetting';
import CompanyInfo from "../pages/Authentication/CompanyInfo";
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import ResetVerification from "../pages/Authentication/ResetVerification";
import  ResetPassword  from "../pages/Authentication/ResetPassword";
import Notifications from "../pages/Notifications/Notifications";
const userRoutes = [

    { path: "/jobwizard", component: JobWizard },
    { path: "/jobdetails/:id", component: JobDetails },
    { path: "/jobdashboard", component: JobDashboard },
    { path: "/profile", component: Profile },
    { path: "/setting", component: AccountSetting },
    { path: "/companyinfo", component: CompanyInfo },
    {path:"/notifications",component:Notifications},
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => localStorage.getItem("token") ? <Redirect to="/jobdashboard" /> : <Redirect to="/login" /> }
    
];

const authRoutes = [
    {path:"/setpassword",component:SetPassword},
    {path:"/login",component:Login},

    { path: "/forgotpassword", component: ForgotPassword },
    { path: "/passwordreset", component: ResetVerification },
    {path:"/resetPassword",component:ResetPassword},
   
    //Authentication
    // { path: "/login", component: Login },
    // { path: "/signup", component: SignUP }
]

export { userRoutes, authRoutes };