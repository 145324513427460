export const PROFILE_DETAILS = 'PROFILE_DETAILS';
export const PROFILE_DETAILS_LOADING = 'PROFILE_DETAILS_LOADING';
export const PROFILE_DETAILS_ERROR = 'PROFILE_DETAILS_ERROR';

const initialState = {
    loading: true,
    error: null,
    profile : {},
}

const profile = (state = initialState, action) => {
    switch (action.type) {
        
        case PROFILE_DETAILS:
            state = { loading: false, error: null, profile : { ...action.payload } }
            break;
        case PROFILE_DETAILS_LOADING:
            state = { ...state, loading: true };
            break;
        case PROFILE_DETAILS_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        default:
            break;
    }
    return state;
}
export default profile;