import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import cloudOutline from "../../../assets/images/cloud-outline.png";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import TextArea from "../../../components/TextArea";
import ApiService from "../../../config/restapi";
import s3api from "../../../helpers/s3apis";

const CompanyDetails = (props) => {
  const [web_urlcharcomplete, setWeb_urlcharcomplete] = useState(0);
  const [compnayLogoImg, setCompnayLogoImg] = useState(null);

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (ext === "jpeg" || ext === "png" || ext === "gif" || ext === "jpg") {
      return true;
    }
    return false;
  }

  function checkUrl(url) {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (!regexp.test(url)) {
      return { valid: false, message: "Enter a valid URL" };
    } else {
      return { valid: true, message: "" };
    }
  }

  const handleAcceptedFiles = async (files) => {
    if (validateFileExtention(files)) {
      console.log(files[0]);
      var ext = files[0].name.split(".").pop();
      if (files[0].size < 5242880) {
        await s3api.upload("logo", ext, files[0]);
      } else {
        toastr.error("Select a file of size less than 5 MB", "", {
          timeOut: 2000,
        });
      }
    } else {
      toastr.error("Please Upload Valid Format", "", { timeOut: 2000 });
    }
  };

  function onChangeTextareaHandler(event) {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = event.target.value.trimStart();
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    if (WordCount <= 500) setWeb_urlcharcomplete(WordCount);
    props.OnchangeCallBack(event.target.value, "websiteurl");
  }

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={1} className="company-details">
        <Form>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">Company Name </Label>
                <Input
                  type="text"
                  disabled={true}
                  value={props.details.name || ""}
                  className="form-control"
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "name")
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-4 text-left">
                <Label>Company Logo</Label>
                {compnayLogoImg && (
                  <div className="company-logo-img">
                    <img src={compnayLogoImg} alt="" className="" />
                    <span
                      className="img-close"
                      onClick={() => setCompnayLogoImg(null)}
                    >
                      &times;
                    </span>
                  </div>
                )}
                {!compnayLogoImg && (
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        <div className=" needsclick" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="needsclick upldoad-logo">
                            <button
                              type="button"
                              className="d-flex btn w-lg btn-outline-shadow waves-effect waves-light bg-uplaod-btn"
                            >
                              {" "}
                              <img
                                className=""
                                src={cloudOutline}
                                alt="cloud"
                              />
                              <div className="font-size-11 ml-2">
                                {" "}
                                UPLOAD PHOTO
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-0 text-left">
                <TextArea
                  labelName="Website URL"
                  isOptional={true}
                  onChangeTextareaHandler={(event) =>
                    onChangeTextareaHandler(event)
                  }
                  value={props.details.website_url}
                />
                {props.details.website_url && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    {checkUrl(props.details.website_url).message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default CompanyDetails;
