import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

import {
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import post_job_icon from "../../assets/images/post_job_icon.png";
import ApiService from "../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import getFormatedDate from "../../helpers/getFormatedDate";
import { getOrganizationDetails } from "../../redux/actions/profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const JobDashboard = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [jobList, setJobList] = useState({});

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  useEffect(() => {
    props.getOrganizationDetails();
        
    }, []);

  function changeMenuState(index) {
    let tempData = { ...jobList };
    tempData.jobs[index].menu = !tempData.jobs[index]["menu"];
    setJobList({ ...tempData });
  }

  function getJobListByStatus(status) {
    localStorage.getItem("token");
    ApiService.getJobListByStatus(status).then((res) => {
      if (res && res.status === 200 && res.data) {
        setJobList(res.data);
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function closePost(data) {
    console.log(data);
    let Obj = {
      organizationId:data.organizationId,
      id: data.id,
      status: "close",
    };
    ApiService.updateJobStatus(Obj).then((res) => {
      if (res && res.status === 200 && res.data && res.data.success) {
        getJobListByStatus("open");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function openJobPost(data) {
    let Obj = {
      organizationId:data.organizationId,
      id: data.id,
      status: "open",
    };
    ApiService.updateJobStatus(Obj).then((res) => {
      if (res && res.status === 200 && res.data && res.data.success) {
        getJobListByStatus("close");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function deleteDraft(data) {
    let Obj = {
      id: data.id,
    };
    ApiService.deleteJobPost(Obj).then((res) => {
      if (res && res.status === 200) {
        getJobListByStatus("incomplete");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }
  
  useEffect(()=>{
    ApiService.getProfileDetails().then((res) => {
      if (res && res.status === 200 && res.data) {
        console.log(res.data)
        console.log(res.data.clients);
        console.log(res.data);
        console.log(res.data && res.data);
        console.log(
          res.data.clients && res.data.clients[0].is_profile_created
        );
        if (
          res.data &&
          res.data.clients &&
          res.data.clients[0].is_profile_created == false
        ) {
          props.history.push("/companyinfo");
        }
      } else {
        console.log(res);
      }
    })
  },[])

  useEffect(() => {
    if (activeTab === "1") {
      getJobListByStatus("open");
    } else if (activeTab === "2") {
      getJobListByStatus("incomplete");
    } else {
      getJobListByStatus("close");
    }
  }, [activeTab]);

  // const data = React.useMemo(() => jobList && jobList.jobs ? jobList.jobs : [] , [])


  return (
    <React.Fragment>
      <div className="page-content job-dashbord">
        <Row>
          <Col
            xl="6"
            lg="6"
            xs="6"
            className="dashboard_mobile_right_padding pl-4 pr-4 text-left green-text-color font-weight-medium"
          >
            Your Job Postings
          </Col>
          <Col
            xl="6"
            lg="6"
            xs="6"
            className="dashboard_mobile_left_padding pl-4 pr-4 text-right"
          >
            <button
              className="btn btn-outline-shadow waves-effect waves-light w-md"
              type="button"
              onClick={() => props.history.push("/jobwizard")}
            >
              <img className="mr-2" src={post_job_icon} alt="job-post" /> POST A
              JOB
            </button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xl="12" lg="12" md="12" className="pl-4 pr-4">
            <div className="job-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Open ({jobList.openCount})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Incomplete ({jobList.incompleteCount})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Closed ({jobList.closeCount})
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        {/* <Row className="mt-2">
          <Col xl="2" lg="3" md="3" className="pl-4 pr-4 text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Jobs"
                  aria-label="Recipient's username"
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row> */}
        {props.isMobile ? (
          <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
            <Col lg="12" className="pl-4 pr-4 text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Job Title</th>
                      <th scope="col">Company/ Location</th>
                      <th scope="col"> Domain</th>
                      <th scope="col"> Skill Tags</th>
                      <th scope="col"> Employment Type</th>
                      <th scope="col"> Total Appointments</th>
                      <th scope="col">
                        {activeTab === "1" && "Added On"}
                        {activeTab === "2" && "Created On"}
                        {activeTab === "3" && "Closed On"}
                      </th>
                      <th className="text-center">
                        {/* <i className="bx bx-cog font-size-17 align-middle"></i> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobList &&
                      jobList.jobs &&
                      jobList.jobs.length > 0 &&
                      jobList.jobs.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={() => {
                              item.status !== "incomplete"
                                ? props.history.push(`/jobdetails/${item.id}`)
                                : props.history.push({
                                  pathname: "/jobwizard",
                                  state: item,
                                });
                            }}
                          >
                            <td>
                              <div className="font-size-16">{item.title}</div>
                              <div className="font-size-12">#{item.id}</div>
                            </td>
                            <td>
                              <div className="font-size-16">{item.company}</div>
                              <div className="font-size-12">
                                {item.location}
                              </div>
                            </td>
                            <td>
                              <div className="font-size-16">{item.domain}</div>
                            </td>
                            <td>
                              <div className="font-size-16 d-flex">
                                {item && item.skills_must && (
                                  <ul className="skills-learns pl-0 mb-0">
                                    {item.skills_must &&
                                      item.skills_must
                                        .slice(0, 2)
                                        .map((item, idx) => {
                                          return (
                                            <li
                                              title={item}
                                              className={classnames(
                                                "d-inline-block mr-2 skills-items skill-container cut-text-ellipsis"
                                              )}
                                              key={idx}
                                            >
                                              <span> {item} </span>
                                            </li>
                                          );
                                        })}
                                  </ul>
                                )}
                                {item.skills_must.length > 2 && (
                                  <span className="font-size-12 light-green-text mt-1">
                                    {" "}
                                    +
                                    {
                                      item.skills_must.slice(
                                        2,
                                        item.skills_must.length
                                      ).length
                                    }
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="font-size-16">
                                {item.employment_type
                                  ? item.employment_type
                                  : "--"}
                              </div>
                            </td>
                            <td>
                              <div
                                className="font-size-16"
                                style={{ textAlign: "center" }}
                              >
                                {item.total_appointments
                                  ? item.total_appointments
                                  : 0}{" "}
                                {/* <span>
                                  <i className="dripicons-dot light-green-text"></i>
                                  <span className="light-green-text font-size-12 text">
                                    0{" "}
                                  </span>{" "}
                                </span> */}
                              </div>
                            </td>
                            <td>
                              <div className="font-size-16">
                                {activeTab === "1" &&
                                  (item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--")}
                                {activeTab === "2" &&
                                  (item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--")}
                                {activeTab === "3" &&
                                  (item.updatedAt
                                    ? getFormatedDate(item.updatedAt, "ll")
                                    : "--")}
                              </div>
                            </td>
                            <td className="text-right">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={(event) => {
                                  event.stopPropagation();
                                  changeMenuState(index);
                                }}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  {activeTab === "2" && (
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() => deleteDraft(item, index)}
                                    >
                                      Delete Draft
                                    </DropdownItem>
                                  )}
                                  {activeTab === "1" && (
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() => {
                                        closePost(item, index);
                                      }}
                                    >
                                      Close Job Post
                                    </DropdownItem>
                                  )}
                                  {activeTab === "3" && (
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() => {
                                        openJobPost(item, index);
                                      }}
                                    >
                                      Open Job Post
                                    </DropdownItem>
                                  )}
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    onClick={() => {
                                      props.history.push({
                                        pathname: "/jobwizard",
                                        state: item,
                                      });
                                    }}
                                  >
                                    Edit Job Post
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>

                  {jobList && jobList.jobs && jobList.jobs.length === 0 && (
                    <tbody className="mt-2 font-weight-normal">
                      <tr>
                        <td colspan="8" className="text-center">
                          <div className="no-result">No Result</div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
            <Col xl="12" lg="12" className="pl-4 pr-4 text-left">
              <Row className="row-header font-weight-medium font-size-16">
                <Col xl="2" lg="2" md="2" xs="2" className="">
                  Job Title
                </Col>
                <Col xl="2" lg="2" md="2" xs="2" className="">
                  Company/ Location
                </Col>
                <Col xl="1" lg="1" md="1" xs="1" className="">
                  Domain
                </Col>
                <Col xl="2" lg="2" md="2" xs="2" className="">
                  Skill Tags
                </Col>
                <Col xl="2" lg="2" md="2" xs="2" className="">
                  Employment Type
                </Col>
                <Col
                  xl="1"
                  lg="1"
                  md="1"
                  xs="1"
                  className=""
                  style={{ paddingRight: "15px" }}
                >
                  Appointments
                </Col>
                <div className="job-w">
                  {activeTab === "1" && "Added On"}
                  {activeTab === "2" && "Created On"}
                  {activeTab === "3" && "Closed On"}
                </div>
                {/* <Col>
                <i className="bx bx-cog font-size-17 align-middle"></i>
                                </Col> */}
              </Row>
            </Col>
            <Col xl="12" lg="12" className="pl-4 pr-4 text-left">
              {jobList &&
                jobList.jobs &&
                jobList.jobs.length > 0 &&
                jobList.jobs.map((item, index) => {
                  return (
                    <Row key={index} className="mt-2 font-weight-normal">
                      <Col xl="12" lg="12" md="12" className="text-left">
                        <Row
                          className="row-items green-text-color cursor-pointer"
                          onClick={() => {
                            item.status !== "incomplete"
                              ? props.history.push(`/jobdetails/${item.id}`)
                              : props.history.push({
                                pathname: "/jobwizard",
                                state: item,
                              });
                          }}
                        >
                          <Col xl="2" lg="2" md="2" xs="2" className="" >
                            <div className="font-size-16" style={{ textOverflow: "ellipsis", "whiteSpace": "nowrap", "overflow": "hidden" }}>{item.title}</div>
                            <div className="font-size-12">#{item.id}</div>
                          </Col>
                          <Col xl="2" lg="2" md="2" xs="2" className="">
                            <div className="font-size-16" style={{ textOverflow: "ellipsis", "whiteSpace": "nowrap", "overflow": "hidden" }}>{item.company}</div>
                            <div className="font-size-12" style={{ textOverflow: "ellipsis", "whiteSpace": "nowrap", "overflow": "hidden" }}>{item.location}</div>
                          </Col>
                          <Col
                            xl="1"
                            lg="1"
                            md="1"
                            xs="1"
                            className="text-truncate"
                          >
                            <div className="font-size-16" style={{ textOverflow: "ellipsis", "whiteSpace": "nowrap", "overflow": "hidden" }}>{item.domain}</div>
                          </Col>
                          <Col xl="2" lg="2" md="2" xs="2" className="">
                            <div className="font-size-16 d-flex">
                              {item && item.skills_must && (
                                <ul className="skills-learns pl-0 mb-0">
                                  {item.skills_must &&
                                    item.skills_must
                                      .slice(0, 2)
                                      .map((item, idx) => {
                                        return (
                                          <li
                                            className={classnames(
                                              "d-inline-block mr-2 skills-items skill-container"
                                            )}
                                            key={idx}
                                          >
                                            <span> {item} </span>
                                          </li>
                                        );
                                      })}
                                </ul>
                              )}
                              {item.skills_must.length > 2 && (
                                <span className="font-size-12 light-green-text mt-1">
                                  {" "}
                                  +
                                  {
                                    item.skills_must.slice(
                                      2,
                                      item.skills_must.length
                                    ).length
                                  }
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col xl="2" lg="2" md="2" xs="2" className="">
                            <div style={{ textOverflow: "ellipsis", "whiteSpace": "nowrap", "overflow": "hidden" }} className="font-size-16">
                              {item.employment_type
                                ? item.employment_type
                                : "--"}
                            </div>
                          </Col>
                          <Col xl="1" lg="1" md="1" xs="1" className="">
                            <div
                              className="font-size-16"
                              style={{ textAlign: "center" }}
                            >
                              <span>
                                {item.total_appointments
                                  ? item.total_appointments
                                  : 0}{" "}
                              </span>
                              {/* <span>
                                <i className="dripicons-dot light-green-text"></i>
                                <span className="light-green-text font-size-12 text">
                                  0{" "}
                                </span>{" "}
                              </span> */}
                            </div>
                          </Col>
                          <div className="job-w">
                            <div className="font-size-16">
                              {activeTab === "1" &&
                                (item.createdAt
                                  ? getFormatedDate(item.createdAt, "ll")
                                  : "--")}
                              {activeTab === "2" &&
                                (item.createdAt
                                  ? getFormatedDate(item.createdAt, "ll")
                                  : "--")}
                              {activeTab === "3" &&
                                (item.updatedAt
                                  ? getFormatedDate(item.updatedAt, "ll")
                                  : "--")}
                            </div>
                          </div>
                          <Col>
                            <Dropdown
                              isOpen={item.menu}
                              toggle={(event) => {
                                event.stopPropagation();
                                changeMenuState(index);
                              }}
                              className="d-inline-block"
                            >
                              <DropdownToggle
                                className="btn header-item waves-effect"
                                id="page-header-user-dropdown"
                                tag="button"
                              >
                                <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                              </DropdownToggle>
                              <DropdownMenu className="green-text-color" right>
                                {activeTab === "2" && (
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() => deleteDraft(item, index)}
                                  >
                                    Delete Draft
                                  </DropdownItem>
                                )}
                                {activeTab === "1" && (
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() => {
                                      closePost(item, index);
                                    }}
                                  >
                                    Close Job Post
                                  </DropdownItem>
                                )}
                                {activeTab === "3" && (
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() => {
                                      openJobPost(item, index);
                                    }}
                                  >
                                    Open Job Post
                                  </DropdownItem>
                                )}
                                <DropdownItem
                                  className="green-text-color"
                                  tag="a"
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/jobwizard",
                                      state: item,
                                    });
                                  }}
                                >
                                  Edit Job Post
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
            </Col>
            <Col xl="12" lg="12" className="pl-4 pr-4 text-center">
              {jobList && jobList.jobs && jobList.jobs.length === 0 && (
                <Row className="mt-2 font-weight-normal">
                  <Col xl="12" className="text-center">
                    <div className="no-result">No Result</div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )
        }
      </div >
    </React.Fragment >
  );
};
const mapStateToProps = (state) => {
  return {
    ...state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrganizationDetails }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobDashboard)
);
