import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Label,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";

// Redux

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
    AvFeedback,
} from "availity-reactstrap-validation";

// actions
import { loginUser } from "../../redux/actions/auth";

import text_logo from "../../assets/images/Zunavish Logo_white_svg.svg";
import logo from "../../assets/images/Zunavish_text.svg";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [elementtype, setElementType] = useState("password");

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        //props.loginUser(values, props.history);
        props.loginUser(email, password, props.history);
    }

    function handlePasswodType(type) {
        let newType = type === "text" ? "password" : "text";
        setElementType(newType);
    }
    function responseGoogle(response) {
        const tokenid = response.tokenObj.id_token;
        props.loginwithGoogle(tokenid, props.history);
    }
    return (
        <React.Fragment>
            <div className="account-pages">
                <div className="float-left left-section">
                    <Row className="w-100">
                        <Col md={12} lg={12}>
                            <div className="pl-4 pb-2">
                                <h5 className="font-size-36 font-weight-bold login-heading">
                                    Login to{" "}
                                    <img src={logo} alt="" height="35" className="pb-2" />
                                </h5>
                            </div>
                            <div className="pl-4">
                                <AvForm
                                    className="form-horizontal login-form"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v);
                                    }}
                                >
                                    {props.error && props.error ? (
                                        <Alert color="danger">{props.error}</Alert>
                                    ) : null}
                                    <AvGroup>
                                        <Label for="email" className="green-text-color">
                                            Email
                                        </Label>
                                        <InputGroup>
                                            <AvInput
                                                name="email"
                                                type="email"
                                                placeholder="Eg:john@gmail.com"
                                                required
                                                onChange={({ target }) => {
                                                    setEmail(target.value.trimStart());
                                                }}
                                            />

                                            <AvFeedback>{email===""?"Email cannot be blank.":"Invalid email."}</AvFeedback>
                                        </InputGroup>
                                    </AvGroup>
                                    <AvGroup>
                                        <div>
                                            <Label for="password" className="green-text-color ">
                                                Password
                                            </Label>
                                            {/* <Link className="float-right link-forgot-password" to="/forgotpassword"   >Forgot Password?</Link >   */}
                                        </div>
                                        <InputGroup>
                                            <AvInput
                                                name="password"
                                                type={elementtype}
                                                placeholder="Enter Password"
                                                style={{ paddingRight: "2rem" }}

                                                required
                                                onChange={({ target }) => {
                                                    setPassword(target.value);
                                                }}
                                            />
                                            <InputGroupAddon
                                                onClick={() => handlePasswodType(elementtype)}
                                                addonType="append"
                                            >
                                                {elementtype === "text" ? (
                                                    <i className="mdi mdi-eye-off-outline font-size-20"></i>
                                                ) : (
                                                    <i className="mdi mdi-eye-outline font-size-20"></i>
                                                )}
                                            </InputGroupAddon>

                                            <AvFeedback>Password is required.</AvFeedback>
                                        </InputGroup>
                                        <div className="pt-1">
                                            <Link className="text_light_green" to="/forgotpassword">
                                                Forgot Password?
                                            </Link>
                                        </div>
                                    </AvGroup>

                                    <div className="mt-3 text-left pb-3">
                                        <button
                                            className="btn bg-green waves-effect waves-light w-lg mr-2"
                                            type="submit"
                                        >
                                            Log In
                                        </button>
                                    </div>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="float-left right-section">
                    <div className="text-container">
                        <div className="company-text-bold">
                            <img src={text_logo} alt="text" />
                        </div>
                        <div className="mt-2 text-center">
                            {/* vivify your business and People */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStatetoProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};
const mapDispatchToProps = {
    loginUser,
};
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Login));
