export const EmploymentTypes = [
  {
    value: 1,
    label: 'Full-Time Employee'
  },
  {
    value: 2,
    label: 'Part-Time Employee'
  },
  {
    value: 3,
    label: 'Temporary Employee'
  },
  {
    value: 4,
    label: 'Seasonal Employee'
  }
];

export const Locations = [
  {
    "value": 1,
    "label": "Mumbai"
  },
  {
    "value": 2,
    "label": "Delhi"
  },
  {
    "value": 3,
    "label": "Bangalore"
  },
  {
    "value": 4,
    "label": "Hyderabad"
  },
  {
    "value": 5,
    "label": "Ahmedabad"
  },
  {
    "value": 6,
    "label": "Chennai"
  },
  {
    "value": 7,
    "label": "Kolkata"
  },
  {
    "value": 8,
    "label": "Surat"
  },
  {
    "value": 9,
    "label": "Pune"
  },
  {
    "value": 10,
    "label": "Jaipur"
  },
  {
    "value": 11,
    "label": "Lucknow"
  },
  {
    "value": 12,
    "label": "Kanpur"
  },
  {
    "value": 13,
    "label": "Nagpur"
  },
  {
    "value": 14,
    "label": "Indore"
  },
  {
    "value": 15,
    "label": "Thane"
  },
  {
    "value": 16,
    "label": "Bhopal"
  },
  {
    "value": 17,
    "label": "Visakhapatnam"
  },
  {
    "value": 18,
    "label": "Pimpri-Chinchwad"
  },
  {
    "value": 19,
    "label": "Patna"
  },
  {
    "value": 20,
    "label": "Vadodara"
  },
  {
    "value": 21,
    "label": "Ghaziabad"
  },
  {
    "value": 22,
    "label": "Ludhiana"
  },
  {
    "value": 23,
    "label": "Agra"
  },
  {
    "value": 24,
    "label": "Nashik"
  },
  {
    "value": 25,
    "label": "Faridabad"
  },
  {
    "value": 26,
    "label": "Meerut"
  },
  {
    "value": 27,
    "label": "Rajkot"
  },
  {
    "value": 28,
    "label": "Kalyan-Dombivali"
  },
  {
    "value": 29,
    "label": "Vasai-Viral"
  },
  {
    "value": 30,
    "label": "Varanasi"
  },
  {
    "value": 31,
    "label": "Srinagar"
  },
  {
    "value": 32,
    "label": "Aurangabad"
  },
  {
    "value": 33,
    "label": "Dhanbad"
  },
  {
    "value": 34,
    "label": "Amritsar"
  },
  {
    "value": 35,
    "label": "Navi Mumbai"
  },
  {
    "value": 36,
    "label": "Allahabad"
  },
  {
    "value": 37,
    "label": "Ranchi"
  },
  {
    "value": 38,
    "label": "Howrah"
  },
  {
    "value": 39,
    "label": "Coimbatore"
  },
  {
    "value": 40,
    "label": "Jabalpur"
  },
  {
    "value": 41,
    "label": "Gwalior"
  },
  {
    "value": 42,
    "label": "Vijayawada"
  },
  {
    "value": 43,
    "label": "Jodhpur"
  },
  {
    "value": 44,
    "label": "Madurai"
  },
  {
    "value": 45,
    "label": "Raipur"
  },
  {
    "value": 46,
    "label": "Kota"
  }
]

export const EducationList = [
  {
    value: 1,
    label: 'X'
  },
  {
    value: 2,
    label: 'XII'
  },
  {
    value: 3,
    label: 'Graduation and above'
  }
]

export const Domains = [
  {
    value: 1,
    label: 'IT/ITES',
    skills: [
      { "label": "Analytics", id: "Analytics" },
      { "label": "Application Development", id: "Application Development" },
      { "label": "Application Maintenance", id: "Application Maintenance" },
      { "label": "Artificial Intelligence and Big Data Analytics", id: "Artificial Intelligence and Big Data Analytics" },
      { "label": "Cloud Computing", id: "Cloud Computing" },
      { "label": "Data Scientists", id: "Data Scientists" },
      { "label": "Customer Relationship Management", id: "Customer Relationship Management" },
      { "label": "Editorial and DTP", id: "Editorial and DTP" },
      { "label": "Engineering Analysis", id: "Engineering Analysis" },
      { "label": "Finance and Accounting", id: "Finance and Accounting" },
      { "label": "Generic", id: "Generic" },
      { "label": "Hardware Development", id: "Hardware Development" },
      { "label": "Hardware Testing", id: "Hardware Testing" },
      { "label": "Human Resource Outsourcing", id: "Human Resource Outsourcing" },
      { "label": "Information Security", id: "Information Security" },
      { "label": "Information/Cyber Security", id: "Information/Cyber Security" },
      { "label": "Infrastructure Management Services (IMS)", id: "Infrastructure Management Services (IMS)" },
      { "label": "Internet of Things", id: "Internet of Things" },
      { "label": "IT Consulting", id: "IT Consulting" },
      { "label": "IT Support Services/Helpdesk", id: "IT Support Services/Helpdesk" },
      { "label": "IT Support Servies/Helpdesk", id: "IT Support Servies/Helpdesk" },
      { "label": "Knowledge Process Outsourcing - Research", id: "Knowledge Process Outsourcing - Research" },
      { "label": "Legal", id: "Legal" },
      { "label": "Legal BPO", id: "Legal BPO" },
      { "label": "Processing", id: "Processing" },
      { "label": "Product Engineering Design", id: "Product Engineering Design" },
      { "label": "Product Lifecycle Management", id: "Product Lifecycle Management" },
      { "label": "Product Manufacturing Support", id: "Product Manufacturing Support" },
      { "label": "Product Research and Design", id: "Product Research and Design" },
      { "label": "Product Support", id: "Product Support" },
      { "label": "Quality Assurance and Engineering", id: "Quality Assurance and Engineering" },
      { "label": "Sales & Marketing/Business Development", id: "Sales & Marketing/Business Development" },
      { "label": "Sales and Pre-Sales", id: "Sales and Pre-Sales" },
      { "label": "Software Development", id: "Software Development" },
      { "label": "Software Testing", id: "Software Testing" },
      { "label": "Technical Documentation Writing", id: "Technical Documentation Writing" },
      { "label": "Testing and QA", id: "Testing and QA" },
      { "label": "Web and Mobile Development", id: "Web and Mobile Development" }
    ]
  },
  {
    value: 2,
    label: 'Ecommerce',
    skills: [
      { "label": "Digital Marketing", id: "Digital Marketing" },
      { "label": "Search Engine Optimization", id: "Search Engine Optimization" },
      { "label": "Data Analytics", id: "Data Analytics" },
      { "label": "CRM", id: "CRM" },
      { "label": "Testing", id: "Testing" },
      { "label": "Website Design", id: "Website Design" },
      { "label": "Advertising", id: "Advertising" },
      { "label": "Artificial intelligence", id: "Artificial intelligence" },
      { "label": "Automation", id: "Automation" },
      { "label": "Software development", id: "Software development" },
      { "label": "Web hosting", id: "Web hosting" }
    ]
  },
  {
    value: 3,
    label: 'Pharma & Healthcare',
    skills: [
      { "label": "Clinical Research", id: "Clinical Research" },
      { "label": "Quality Assurance", id: "Quality Assurance" },
      { "label": "Bioinformatics", id: "Bioinformatics" },
      { "label": "Pharma Sales", id: "Pharma Sales" },
      { "label": "Product Development", id: "Product Development" },
      { "label": "Pharmacy Technicians", id: "Pharmacy Technicians" },
      { "label": "Healthcare Analytics", id: "Healthcare Analytics" },
      { "label": "Healthcare Sales", id: "Healthcare Sales" },
      { "label": "Healthcare Complaince", id: "Healthcare Complaince" },
      { "label": "Healthcare Consulting", id: "Healthcare Consulting" },
      { "label": "Basic Life Support(BFS)", id: "Basic Life Support(BFS)" }
    ]
  },
  {
    value: 5,
    label: 'Banking & Financials',
    skills: [
      { "label": "Securities & Stockbroking", id: "Securities & Stockbroking" },
      { "label": "Investment Banking", id: "Investment Banking" },
      { "label": "Retail Banking", id: "Retail Banking" },
      { "label": "Insurance", id: "Insurance" },
      { "label": "Assest Management", id: "Assest Management" },
      { "label": "Sales & marketing", id: "Sales & marketing" },
      { "label": "Commercial Banking", id: "Commercial Banking" },
      { "label": "Private Banking", id: "Private Banking" },
      { "label": "Core Banking", id: "Core Banking" },
      { "label": "Internet Banking", id: "Internet Banking" },
      { "label": "Banking Law", id: "Banking Law" },
      { "label": "Banking Relationships", id: "Banking Relationships" },
      { "label": "Banking Software", id: "Banking Software" },
      { "label": "SME Banking", id: "SME Banking" },
      { "label": "Accounting", id: "Accounting" },
      { "label": "Financial Advisory", id: "Financial Advisory" },
      { "label": "Financial Planning", id: "Financial Planning" },
      { "label": "Financial Risk", id: "Financial Risk" }
    ]
  }
];