import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import TextArea from "../../../components/TextArea";
import Select from "react-select";
import { Domains } from "../../../helpers/constant_variables";

const CompnaySizes = [
  {
    value: 1,
    label: "0-10 employees",
  },
  {
    value: 2,
    label: "10-50 employees",
  },
  {
    value: 3,
    label: "50-200+ employees",
  },
];

const AboutCompany = (props) => {
  const [descriptioncharcomplete, setDescriptioncharcomplete] = useState(0);

  function onChangeTextareaHandler(event) {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = event.target.value.trimStart();
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    console.log(WordCount);
    if (WordCount <= 500) {
      setDescriptioncharcomplete(WordCount);
      
      event.target.value = event.target.value
        .replace(/[^\w\s\.\,]/gi, "")
        .trimStart();
        props.OnchangeCallBack(event.target.value, "description");
    }

    
  }

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={2} className="about-company text-left">
        <Form>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">Company Size </Label>
                <Select
                  className="select-container"
                  value={props.details.selectedOpt}
                  onChange={(selectedOption) =>
                    props.OnchangeCallBack(selectedOption, "size")
                  }
                  options={CompnaySizes}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
                {props.details.size != undefined && !props.details.size && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    Select a company size
                  </label>
                )}

                {/* <Input type="text" value={props.details.size || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'size')} /> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">Industry</Label>
                {/* <Input type="text" value={props.details.industry || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'industry')} /> */}
                <Select
                  className="select-container"
                  value={props.details.industryObj}
                  onChange={(selectedOption) =>
                    props.OnchangeCallBack(selectedOption, "industry")
                  }
                  options={Domains}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
                {props.details.industryObj != undefined &&
                  !props.details.industryObj && (
                    <label style={{ "margin-bottom": "0", color: "red" }}>
                      {" "}
                      Select an Industry type from the list.
                    </label>
                  )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <TextArea
                  labelName="Tell us about the company"
                  isOptional={true}
                  onChangeTextareaHandler={(event) =>
                    onChangeTextareaHandler(event)
                  }
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={descriptioncharcomplete}
                  value={props.details.description}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">Company Location</Label>
                <Input
                  type="select"
                  value={props.details.location || ""}
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "location")
                  }
                  name="select"
                  id="exampleCity"
                >
                  <option>Select</option>
                  <option>Mumbai</option>
                  <option>Delhi</option>
                  <option>Bangalore</option>
                  <option>Hyderabad</option>
                  <option>Ahmedabad</option>
                  <option>Chennai</option>
                  <option>Kolkata</option>
                  <option>Surat</option>
                  <option>Pune</option>
                  <option>Jaipur</option>
                  <option>Lucknow</option>
                  <option>Kanpur</option>
                  <option>Nagpur</option>
                  <option>Indore</option>
                  <option>Thane</option>
                  <option>Bhopal</option>
                  <option>Visakhapatnam</option>
                  <option>Pimpri-Chinchwad</option>
                  <option>Patna</option>
                  <option>Vadodara</option>
                  <option>Ghaziabad</option>
                  <option>Ludhiana</option>
                  <option>Agra</option>
                  <option>Nashik</option>
                  <option>Faridabad</option>
                  <option>Meerut</option>
                  <option>Rajkot</option>
                  <option>Kalyan-Dombivali</option>
                  <option>Vasai-Vira</option>
                  <option>Varanasi</option>
                  <option>Srinagar</option>
                  <option>Aurangabad</option>
                  <option>Dhanbad</option>
                  <option>Amritsar</option>
                  <option>Navi Mumbai</option>
                  <option>Allahabad</option>
                  <option>Ranchi</option>
                  <option>Howrah</option>
                  <option>Coimbatore</option>
                  <option>Jabalpur</option>
                  <option>Gwalior</option>
                  <option>Vijayawada</option>
                  <option>Jodhpur</option>
                  <option>Madurai</option>
                  <option>Raipur</option>
                  <option>Kota</option>
                </Input>
                {props.details.location != undefined &&
                  !props.details.location && (
                    <label style={{ "margin-bottom": "0", color: "red" }}>
                      {" "}
                      Select a location from the list.
                    </label>
                  )}

                {/* <Input type="text" value={props.details.location || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'location')} /> */}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default AboutCompany;
