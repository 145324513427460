import React, { useState } from "react";

import { Row, Col, Alert, Label, InputGroup, InputGroupAddon } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";

// image
import rightIcon from "../../assets/images/right.png";
import { setpassword } from "../../redux/actions/auth";
import text_logo from "../../assets/images/Zunavish Logo_white_svg.svg";

const SetPassword = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [pwdElementtype, setPwdElementType] = useState("password");
    const [confirmPwdElementType, setConfirmPwdElementType] = useState("password");
    const [confirmpassErr, setConfirmPassErr] = useState("This field is required.");
    const [showRightIcon, setShowRightIcon] = useState(false);

    // handleValidSubmit
    function handleValidSubmit() {
        const query = new URLSearchParams(props.location.search);
        props.setpassword(password, confirmPassword, query.get("token"), props.history);
    }

    function handlePasswodType(type) {
        let newType = type === "text" ? "password" : "text";
        setPwdElementType(newType);
    }
    function handleConfirmPasswodType(type) {
        let newType = type === "text" ? "password" : "text";
        setConfirmPwdElementType(newType);
    }

    function onConfrmPasswordChange(target) {
        setConfirmPassword(target.value);
        if (target.value && password !== target.value) {
            setConfirmPassErr("Passwords do not match.");
        } else {
            setConfirmPassErr("This field is required.");
        }
    }

    function validateNumericUpperCase(value) {
        let regex = /(?=.*[A-Z])(?=.*[0-9])/;
        if (!value.match(regex)) {
            return false;
        }
        return true;
    }

    function onChangePassword(target) {
        setPassword(target.value);
        if (target.value && target.value.length >= "8" && validateNumericUpperCase(target.value)) {
            // setShowRightIcon(true);
        } else {
            setShowRightIcon(false);
        }
    }
    console.log(props.match);
    return (
        <React.Fragment>
            <div className="account-pages">
                <div className="float-left left-section">
                    <Row className="w-100">
                        <Col className="col-12">
                            <div className="pl-4 pb-2">
                                <h5 className="font-size-18 font-weight-bold">Set password</h5>
                            </div>
                            <div className="pl-4">
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v);
                                    }}
                                >
                                    {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}
                                    <AvGroup>
                                        <Label for="password" className="green-text-color">
                                            Password
                                        </Label>
                                        <InputGroup>
                                            <AvInput
                                                name="password"
                                                type={pwdElementtype}
                                                placeholder="Enter Password"
                                                required
                                                onChange={({ target }) => {
                                                    onChangePassword(target);
                                                }}
                                                style={{ paddingRight: "2rem" }}

                                            />
                                            <InputGroupAddon
                                                onClick={() => handlePasswodType(pwdElementtype)}
                                                addonType="append"
                                            >
                                                {pwdElementtype === "text" ? (
                                                    <i className="mdi mdi-eye-off-outline font-size-20"></i>
                                                ) : (
                                                    <i className="mdi mdi-eye-outline font-size-20"></i>
                                                )}
                                            </InputGroupAddon>
                                            <AvFeedback>This field is required.</AvFeedback>
                                        </InputGroup>
                                    </AvGroup>
                                    <AvGroup className="font-weight-light pass-err-desc">
                                        <p className="mb-0 font-weight-200 roboto-light text-muted">
                                            {" "}
                                            Password must be{" "}
                                        </p>
                                        {showRightIcon ? (
                                            <ul className="list-unstyled">
                                            <li>
                                                
                                                {password?.length >= 8 && <img alt="no image1" src={rightIcon}></img>}
                                                Minimum of 8 characters
                                            </li>
                                            <li>
                                                {/[A-Z]/.test(password) && < img alt="no image2" src={rightIcon}></img>}
                                                Atleast 1 Uppercase
                                            </li>
                                            <li>
                                                {/\d/.test(password) && < img alt="no image2" src={rightIcon}></img>}Atleast 1 Numeric
                                            </li>
                                        </ul>
                                        ) : (
                                            <ul className="list-unstyled pl-3">
                                            <li>
                                                
                                                {password?.length >= 8 && <img alt="no image1" src={rightIcon}></img>}
                                                Minimum of 8 characters
                                            </li>
                                            <li>
                                                {/[A-Z]/.test(password) && < img alt="no image2" src={rightIcon}></img>}
                                                Atleast 1 Uppercase
                                            </li>
                                            <li>
                                                {/\d/.test(password) && < img alt="no image2" src={rightIcon}></img>}Atleast 1 Numeric
                                            </li>
                                        </ul>
                                        )}
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="cpassword" className="green-text-color">
                                            Confirm Password
                                        </Label>
                                        <InputGroup>
                                            <AvInput
                                                name="cpassword"
                                                type={confirmPwdElementType}
                                                placeholder="Enter Confirm Password"
                                                onChange={({ target }) => {
                                                    onConfrmPasswordChange(target);
                                                }}
                                                style={{ paddingRight: "2rem" }}

                                                validate={{
                                                    required: { value: true },
                                                    match: { value: "password" },
                                                }}
                                            />
                                            <InputGroupAddon
                                                onClick={() => handleConfirmPasswodType(confirmPwdElementType)}
                                                addonType="append"
                                            >
                                                {confirmPwdElementType === "text" ? (
                                                    <i className="mdi mdi-eye-off-outline font-size-20"></i>
                                                ) : (
                                                    <i className="mdi mdi-eye-outline font-size-20"></i>
                                                )}
                                            </InputGroupAddon>
                                            <AvFeedback>{confirmpassErr} </AvFeedback>
                                            {password && password === confirmPassword ? (
                                                <div className="pw-match-success">Passwords match.</div>
                                            ) : (
                                                ""
                                            )}
                                        </InputGroup>
                                    </AvGroup>
                                    <div className="mt-3 text-left">
                                        <button className="btn bg-green waves-effect waves-light w-lg" type="submit">
                                            Set Password
                                        </button>
                                    </div>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="float-left right-section">
                    <div className="text-container">
                        <div className="company-text-bold">
                            <img src={text_logo} alt="text" />
                        </div>
                        <div className="mt-2 text-center">{/* vivify your business and People */}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStatetoProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};
const mapDispatchToProps = {
    setpassword,
};
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(SetPassword));
