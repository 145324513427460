import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import CompanyWizard from './CompanyWizard';
import logo from '../../../assets/images/Zunavish_Logo_SVG.svg';
import { Link } from 'react-router-dom';


const CompanyInfo = (props) => {

    function onCompleteAboutYourselfCallBack() {
        //setIsCompleteProfile(true);
    }

    return (
        <React.Fragment>
            {console.log('profile data', props)}

            <div className="page-content p-0 mt-0 company-info">
                <div className='logo-fixed'>
                    <Link to="/">
                        <img src={logo} alt='logo'></img>
                    </Link>
                </div>
                <Container className="about-page-auth" fluid>
                    <Row>
                        <Col xs="12" xl="3" sm="3"></Col>
                        <Col xs="12" xl="6" sm="6" className="text-center">
                            <h5 className="mb-0 font-size-27">Welcome to Zunavish!</h5>
                            <div className="mt-3" style={{ "fontWeight": "300" }}>
                                <p className="font-size-14"> Complete your profile and get access to young talents around the world</p>
                            </div>
                            <CompanyWizard
                                title="Complete your profile and get more opportunities to connect with people"
                                onCompleteAboutYourselfCallBack={() => onCompleteAboutYourselfCallBack()}
                            />
                        </Col>
                        <Col xs="12" xl="3" sm="3"></Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default CompanyInfo;