import axios from "axios";
const API_END_POINT = "https://api.zunavish.com/api";

const headers = {
  "Content-Type": "application/json",
  headers: { "Access-Control-Allow-Origin": "*" },
};
const hea = {
  token: `${localStorage.getItem("token")}`,
};

export default class RestApi {
  static addCompanyDetails(data) {
    return axios.post(API_END_POINT + "/organizations/add", data, hea);
  }

  static uploadDoc(formData) {
    return axios.post(
      API_END_POINT + "/jobs/upload/document",
      formData,
      headers
    );
  }

  static addJobPost(data) {
    return axios.post(API_END_POINT + "/jobs/add", data, headers);
  }

  static downloadcsv(data) {
    return axios.get(
      API_END_POINT +
        `/jobs/client/csv?id=${data.id}&token=${localStorage.getItem("token")}`
    );
  }
  static deleteJobPost(data) {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${localStorage.getItem("token")}`,
    };
    // return axios.delete(API_END_POINT+'/jobs/delete',data, headers);
    return fetch(API_END_POINT + "/jobs/delete", {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static getJobListByStatus(status) {
    return axios.get(API_END_POINT + `/jobs/list?status=${status}`, headers);
  }

  static addJobPost(data) {
    return axios.post(API_END_POINT + "/jobs/add", data, headers);
  }

  static deleteJobPost(data) {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${localStorage.getItem("token")}`,
    };
    // return axios.delete(API_END_POINT+'/jobs/delete',data, headers);
    return fetch(API_END_POINT + "/jobs/delete", {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static getJobListByStatus(status) {
    return axios.get(API_END_POINT + `/jobs/list?status=${status}`, headers);
  }

  static getJobDetails(id) {
    return axios.get(API_END_POINT + `/jobs/client?id=${id}`, headers);
  }

  static updateJobStatus(data) {
    return axios.put(API_END_POINT + `/jobs/update`, data, headers);
  }

  static getJobApplicationByStatus(id, status) {
    return axios.get(
      API_END_POINT + `/jobs/applications?id=${id}&status=${status}`,
      headers
    );
  }

  static updateJobApplicationStatus(data) {
    return axios.put(API_END_POINT + `/jobs/change_status`, data, headers);
  }

  static getProfileDetails() {
    return axios.get(API_END_POINT + `/organizations/`, headers);
  }

  static editProfileData(data) {
    return axios.put(API_END_POINT + "/organizations/update", data, headers);
  }

  static uploadLogo(data) {
    return axios.post(API_END_POINT + "/client/upload/logo", data, headers);
  }

  static sendMail(data) {
    return axios.post(API_END_POINT + `/jobs/sendmail`, data, headers);
  }

  static getJobList(value) {
    return axios.get(API_END_POINT + `/client/search?query=${value}`, headers);
  }

  static getS3UploadURL(filetype, ext, id) {
    const headr = {
      headers: {
        token: `${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    if (id) {
      return axios.post(
        API_END_POINT + "/uploadURL",
        {
          fileType: filetype,
          fileId: id,
          fileExtension: ext,
        },
        headr
      );
    } else {
      return axios.post(
        API_END_POINT + "/uploadURL",
        {
          fileType: filetype,

          fileExtension: ext,
        },
        headr
      );
    }
  }
  static uploadToS3(url, file) {
    return axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  }
}
