import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

// Redux

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

// actions
import { forgotpassword, newPage } from "../../redux/actions/auth";

import text_logo from "../../assets/images/Zunavish Logo_white_svg.svg";
import Common from "../../helpers/common";

//import { loginUser,apiError } from '../../store/actions';

const Login = (props) => {
  useEffect(() => props.newPage(), []);

  const [email, setEmail] = useState("");

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    //props.loginUser(values, props.history);
    props.forgotpassword(email, props.history);
  }

  return (
    <React.Fragment>
      <div className="account-pages">
        <div className="float-left left-section">
          <Row className="w-100">
            <Col md={12} lg={12}>
              <div className="pl-4 pb-2">
                <h5 className="font-size-36 font-weight-bold login-heading">
                  Forgot password?
                </h5>
              </div>
              <div className="pl-4">
                <AvForm
                  className="form-horizontal login-form"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}
                >
                  {props.error && props.error ? (
                    <Alert color="danger">{props.error}</Alert>
                  ) : null}
                  {/* <AvGroup>
                                        <AvField  className="text-sub-heading" 
                                           />
                                        </AvGroup> */}
                  <AvGroup>
                    <Label for="email" className="green-text-color">
                      Email
                    </Label>
                    <InputGroup>
                      <AvInput
                        name="email"
                        type="email"
                        placeholder="Eg: john@gmail.com"
                        required
                        onChange={({ target }) => {
                          target.value = Common.trimStart(target.value);
                          setEmail(target.value);
                        }}
                      />

                      <AvFeedback>{email===""?"Email cannot be blank.":"Invalid email."}</AvFeedback>
                    </InputGroup>
                  </AvGroup>

                  <div className="mt-3 text-left pb-3 auth-form-submit">
                    <button
                      className="btn bg-green waves-effect waves-light w-lg mr-2"
                      type="submit"
                    >
                      Send Link
                    </button>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
        <div className="float-left right-section">
          <div className="text-container">
            <div className="company-text-bold">
              <img src={text_logo} alt="text" />
            </div>
            <div className="mt-2 text-center">
              {/* vivify your business and People */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};
const mapDispatchToProps = {
  forgotpassword,
  newPage,
};
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Login));
