import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap";
import TextArea from "../../../components/TextArea";
import Select from "react-select";
import classnames from "classnames";
import {
  EmploymentTypes,
  Locations,
} from "../../../helpers/constant_variables";
import common from "../../../helpers/common";
import { Domains } from "../../../helpers/constant_variables";

import Autocomplete from "react-autocomplete";
import { WithContext as ReactTags } from "react-tag-input";
import UploadFile from "../../../components/UploadFile";

const DiversityList = [
  {
    value: 1,
    label: "Male",
  },
  {
    value: 2,
    label: "Female",
  },
  {
    value: 3,
    label: "NA",
  },
];

const MaxAgeList = [
  {
    value: 1,
    label: "20",
  },
  {
    value: 2,
    label: "30",
  },
  {
    value: 3,
    label: "40",
  },
  {
    value: 4,
    label: "50",
  },
];

const MinAgeList = [
  {
    value: 1,
    label: "18",
  },
  {
    value: 2,
    label: "21",
  },
  {
    value: 3,
    label: "30",
  },
  {
    value: 4,
    label: "40",
  },
];

const EducationList = [
  {
    value: 1,
    label: "X",
  },
  {
    value: 2,
    label: "XII",
  },
  {
    value: 3,
    label: "Graduation and above",
  },
];

const JobForm = (props) => {
  const [skillsMustArray, setSkillsMustArray] = useState([]);
  const [skillsGoodArray, setSkillsGoodArray] = useState([]);
  const [newFile,setNewFile] = useState(false);

  useEffect(()=>{
    
    if(props.jobfields.roleandResponsibilty.length>0 && props.roleCharComplete===0){
      var regex = /\s+/gi;
      var WordCount =
      props.jobfields.roleandResponsibilty === ""
          ? 0
          : props.jobfields.roleandResponsibilty.trim().replace(regex, " ").split(" ").length;
          props.setRoleCharComplete(WordCount);
    }
    if(props.jobfields.otherDetails.length>0 && props.otherDetailsCharComplete===0){
      var regex = /\s+/gi;
      var WordCount =
      props.jobfields.otherDetails === ""
          ? 0
          : props.jobfields.otherDetails.trim().replace(regex, " ").split(" ").length;
          props.setOtherDetailsCharComplete(WordCount);;
    }
    if(props.jobfields.specification.length>0 && props.specificationCharComplete===0){
      var regex = /\s+/gi;
      var WordCount =
      props.jobfields.specification === ""
          ? 0
          : props.jobfields.specification.trim().replace(regex, " ").split(" ").length;
          props.setSpecificationCharComplete(WordCount);;
    }
    if (props.jobfields.domain) {
      const getDomainIndex = props.findWithAttr(Domains, "label", props.jobfields.domain);
      const results = Domains[getDomainIndex].skills.filter((person) => {
        if (props.jobfields.mustSkillVal) {
          return person.label.toLowerCase().includes(props.jobfields.mustSkillVal);
        } else if (props.jobfields.goodSkillVal) {
          return person.label.toLowerCase().includes(props.jobfields.goodSkillVal);
        } else {
          return Domains[getDomainIndex].skills;
        }
      });
      props.setJobfields({
        ...props.jobfields,
        d_skills: results,
      });
    }
  },[props.jobfields.roleandResponsibilty,props.jobfields.otherDetails,props.jobfields.specification])

  const ChangeSkills = (e, type) => {
    if (e.key === "Enter") {
      if (type === "mustskill") {
        const tempData = [...skillsMustArray];
        //let obj = {name:e.target.value,proficiency:'begineer'};
        tempData.push(e.target.value);
        setSkillsMustArray([...tempData]);
      } else {
        const tempData = [...skillsGoodArray];
        //let obj = {name:e.target.value,proficiency:'begineer'};
        tempData.push(e.target.value);
        setSkillsGoodArray([...tempData]);
      }
    }
  };

  const RemoveSkill = (index, type) => {
    if (type === "mustskill") {
      const tempData = [...skillsMustArray];
      tempData.splice(index, 1);
      setSkillsMustArray([...tempData]);
    } else {
      const tempData = [...skillsGoodArray];
      tempData.splice(index, 1);
      setSkillsGoodArray([...tempData]);
    }
  };

  // useEffect(() => {
  //     this.input.focus();
  // }, []);

  const KeyCodes = {
    tab: 9,
    enter: 13,
  };

  const delimiters = [KeyCodes.tab, KeyCodes.enter];

  const handleDelete = (i) => {
    const tags = [...props.jobfields?.skills_must];
    const tempTag = [...tags.filter((tag, index) => index !== i)];
    props.onChangeTextareaHandler(tempTag, "mustskills");
  };

  const handleAddition = (tag) => {
    console.log("In");
    tag.label = tag.label.trim();
    if (/^[a-zA-Z\s]+$/.test(tag.label)) {
      console.log("INN");
      const tempTag = [...props.jobfields?.skills_must, tag.label];
      props.onChangeTextareaHandler(tempTag, "mustskills");
    }
  };

  const handleDeleteG = (i) => {
    const tags = [...props.jobfields?.skills_good];
    const tempTag = [...tags.filter((tag, index) => index !== i)];
    props.onChangeTextareaHandler(tempTag, "goodskills");
  };

  const handleAdditionG = (tag) => {
    console.log(tag);
    tag.label = tag.label.trim();
    if (/^[a-zA-Z\s]+$/.test(tag.label)) {
      const tempTag = [...props.jobfields?.skills_good, tag.label];
      console.log(tempTag);
      props.onChangeTextareaHandler(tempTag, "goodskills");
    } else {
    }
  };
  console.log(props.jobfields?.skills_good);

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={1} className="personal-details job-form">
        <Form>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">
                  Job Title <span className="label-required"> * </span>{" "}
                </Label>
                <Input
                  maxLength="40"
                  type="text"
                  value={props.jobfields.title}
                  onChange={(event) =>
                    props.onChangeTextareaHandler(event, "title")
                  }
                  className="form-control"
                />
                {props.jobfields.title !== undefined && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    {props?.errors?.title?.message}
                  </label>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mb-4 text-left">
                <Label>
                  Company <span className="label-required"> * </span>
                </Label>
                <Input
                  type="text"
                  disabled={true}
                  value={props.companyName || ""}
                  name="select"
                  // onChange={(event) => props.onChangeTextareaHandler(event, 'company')}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            
            <Col lg="6">
              <FormGroup className="cust-age-width text-left">
                <Label for="basicpill-lastname-input2">
                  Location <span className="label-required"> * </span>
                </Label>
                <Select
                  className="select-container"
                  value={props.jobfields.locationObj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "location")
                  }
                  options={common.getSortedArray(Locations, "label")}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                  isDisabled={props.jobfields.locationDisabled}
                />
                {/* 
                                <Input disabled={props.jobfields.locationDisabled} type="select" value={props.jobfields.location} name="select" onChange={(event) => props.onChangeTextareaHandler(event, 'location')} >
                                   
                                </Input> */}
                <FormGroup className="pt-2" check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={props.jobfields.isRemoteLoc}
                      onChange={(event) =>
                        props.onChangeTextareaHandler(event, "remote")
                      }
                    />{" "}
                    This is a remote job
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Domain <span className="label-required"> * </span>
                </Label>
                <Select
                  className="select-container"
                  value={props.jobfields.domainObj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "domain")
                  }
                  options={Domains}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
                {(!props.jobfields.domain || props.jobfields.domain==="")  && (
                  <label style={{ marginTop: "-48px", color: "red" }}>
                    {" "}
                    {props?.errors?.domain?.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Employment Type <span className="label-required"> * </span>
                </Label>
                <Select
                  className="select-container"
                  value={props.jobfields.emp_type_obj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "emptype")
                  }
                  options={EmploymentTypes}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
                {(!props.jobfields.emp_type || props.jobfields.emp_type==="")  && (
                  <label style={{ marginTop: "-48px", color: "red" }}>
                    {" "}
                    {props?.errors?.emptype?.message}
                  </label>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Education Details <span className="label-required"> * </span>
                </Label>
                <Select
                  className="select-container"
                  value={props.jobfields.edetailsObj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "edetails")
                  }
                  options={EducationList}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
                {(!props.jobfields.education_details || props.jobfields.education_details==="")  && (
                  <label style={{ marginTop: "-48px", color: "red" }}>
                    {" "}
                    {props?.errors?.education?.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Compensation  
                                    <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span></Label>
                                <Input type="select" name="select" >
                                    <option>Select</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </Input>       
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Job Level</Label>
                                <Input type="select" name="select" >
                                    <option>Senior</option>
                                    <option>Junior</option>
                                </Input>       
                            </FormGroup>
                        </Col>
                    </Row>          */}
          <Row className="border-bottom">
            <Col lg="6">
              <FormGroup className="text-left position-relative">
                <Label for="basicpill-lastname-input2">
                  Skills - Must have <span className="label-required"> * </span>
                </Label>
                {/* <Input type="text" placeholder='Type skills and enter' onKeyDown={(e) => props.onChangeTextareaHandler(e, 'mustskills')} name="select" ></Input> */}
                <ReactTags
                  tags={props.jobfields?.skills_must?.map((e) => ({
                    id: e,
                    label: e,
                  }))}
                  suggestions={props.jobfields.d_skills || []}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  minQueryLength={0}
                  allowDragDrop={false}
                  placeholder="Type Skill and Click Enter If Not Found"
                  inputFieldPosition="top"
                  labelField={"label"}
                  renderSuggestion={({ label }, query) => (
                    <div style={{ marginLeft: "0px" }}>{label}</div>
                  )}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Skills - Good to have{" "}
                </Label>
                {/* <Input type="text" placeholder='Type skills and enter' onKeyDown={(e) => props.onChangeTextareaHandler(e, 'goodskills')} name="select" ></Input> */}

                <ReactTags
                  tags={props.jobfields?.skills_good?.map((e) => ({
                    id: e,
                    label: e,
                  }))}
                  suggestions={props.jobfields.d_skills || []}
                  delimiters={delimiters}
                  handleDelete={handleDeleteG}
                  handleAddition={handleAdditionG}
                  minQueryLength={0}
                  allowDragDrop={false}
                  placeholder="Type Skill and Click Enter If Not Found"
                  inputFieldPosition="top"
                  labelField={"label"}
                  renderSuggestion={({ label }, query) => (
                    <div style={{ marginLeft: "0px" }}>{label}</div>
                  )}
                />

                {/* <Autocomplete
                                    getItemValue={(item) => item.label}
                                    items={props.jobfields.d_skills || []}
                                    renderItem={(item, isHighlighted) =>{
                                       return <div  key={Math.random()} className='p-2' style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                            {item.label}
                                        </div>
                                        }
                                    }
                                    renderInput={(props1)=>{
                                        return <input placeholder='Type or Select skills and enter'
                                            className='form-control' {...props1}
                                            onKeyDown = {(event)=> {
                                             if (event && event.key && event.key === 'Enter') 
                                                props.onChangeTextareaHandler(event.target.value, 'goodskills','enter')
                                            }}
                                        />
                                    }}
                                    menuStyle={{
                                        position:'absolute',
                                        left: "0px",
                                        top: "101%",
                                        borderRadius: '4px',
                                        boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
                                        zIndex: 1,
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                    }}
                                    wrapperStyle={{display:'block', position:'relative'}}
                                    value={props.jobfields && props.jobfields.goodSkillVal}
                                    onChange={(e) => props.onChangeTextareaHandler(e.target.value, 'goodskills')}   
                                    onSelect={(val) => props.onChangeTextareaHandler(val, 'goodskills')}
                                />
                                
                                {props.jobfields.skills_good && props.jobfields.skills_good.length > 0 && <ul className="skills-learns pl-0 mt-2 mb-0">
                                    {props.jobfields.skills_good && props.jobfields.skills_good.map((item, idx) => {
                                        return (
                                            <li className={classnames("d-inline-block mr-2 skills-items skill-container", { 'mb-1': (skillsGoodArray.length - 1) !== idx })} key={idx}>
                                                <span className="mr-1"> {item} </span>
                                                <span className='img-close cursor-pointer' onClick={() => props.RemoveSkill(idx, 'goodskills')}>&times;</span>
                                            </li>
                                        )
                                    })}
                                </ul>} */}
              </FormGroup>
            </Col>
          </Row>
          <Row className="pb-2 mt-2">
            <Col lg="6">
              <div className="text-left position-relative">
                <Label for="basicpill-lastname-input2">
                  Min Experience
                  <span className="label-required"> * </span>
                  {/* <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span> */}
                </Label>
                <Input
                  maxLength={2}
                  type="tel"
                  value={props.jobfields.minexp || ""}
                  onChange={(event) =>
                    props.onChangeTextareaHandler(event, "minexp")
                  }
                ></Input>
                <span className="year-text">years</span>
              </div>
              {props.jobfields.minexp !== undefined && (
                <label
                  style={{ float: "left", "margin-bottom": "0", color: "red" }}
                >
                  {" "}
                  {props?.errors?.minexp?.message}
                </label>
              )}
              {props.jobfields.minexp !== undefined &&
                props.jobfields.maxexp !== undefined &&
                parseInt(props.jobfields.minexp) >
                  parseInt(props.jobfields.maxexp) && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    Minimum experience should be lesser than maximum experience.
                  </label>
                )}
            </Col>
            <Col lg="6">
              <div className="text-left position-relative">
                <Label for="basicpill-lastname-input2">
                  Max Experience
                  <span className="label-required"> * </span>
                  {/* <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span> */}
                </Label>
                <Input
                  maxLength={2}
                  type="tel"
                  value={props.jobfields.maxexp || ""}
                  onChange={(event) =>
                    props.onChangeTextareaHandler(event, "maxexp")
                  }
                ></Input>

                <span className="year-text">years</span>
              </div>
              {props.jobfields.maxexp !== undefined && (
                <label
                  style={{ float: "left", "margin-bottom": "0", color: "red" }}
                >
                  {" "}
                  {props?.errors?.maxexp?.message}
                </label>
              )}
            </Col>
          </Row>
          {/* <Row className="pt-2"> */}
            {/* <Col lg="6">
                            <FormGroup className="text-left">
                                {console.log("props.jobfields", props.jobfields)}
                                <Label for="basicpill-lastname-input2">Diversity</Label>
                                <Select
                                    className="select-container"
                                    value={props.jobfields.diversityObj}
                                    onChange={(selectedOption) => props.onChangeTextareaHandler(selectedOption, 'diversity')}
                                    options={DiversityList}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col> */}
            
          {/* </Row> */}
          {/* <Row className='border-bottom'>
                        <Col lg="6">
                            <FormGroup className="text-left position-relative">
                                <Label for="basicpill-lastname-input2">Min Age
                                <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span>
                                </Label>
                                <Select
                                    className="select-container"
                                    value={props.jobfields.minAgeObj}
                                    onChange={(selectedOption) => props.onChangeTextareaHandler(selectedOption, 'minage')}
                                    options={MinAgeList}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                />
                                <span className='year-text'>years</span>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="text-left position-relative">
                                <Label for="basicpill-lastname-input2">Max Age
                                    <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span>
                                </Label>
                                <Select
                                    className="select-container"
                                    value={props.jobfields.maxAgeObj}
                                    onChange={(selectedOption) => props.onChangeTextareaHandler(selectedOption, 'maxage')}
                                    options={MaxAgeList}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                />
                                <span className='year-text'>years</span>
                            </FormGroup>
                        </Col>
                    </Row> */}
          <Row className="pt-2">
            <Col lg="12">
              <FormGroup className="text-left">
                <TextArea
                  labelName="Roles and Responsibilities"
                  onChangeTextareaHandler={(event) =>
                    props.onChangeTextareaHandler(event, "roles")
                  }
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={props.roleCharComplete}
                  value={props.jobfields.roleandResponsibilty}
                />
                {props.jobfields.roleandResponsibilty !== undefined && (
                  <label style={{ marginTop: "-48px", color: "red" }}>
                    {" "}
                    {props?.errors?.roleandResponsibilty?.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <TextArea
                  labelName="Other details related to the job"
                  onChangeTextareaHandler={(event) =>
                    props.onChangeTextareaHandler(event, "otherdetails")
                  }
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={props.otherDetailsCharComplete}
                  value={props.jobfields.otherDetails}
                />
                {props.jobfields.otherDetails !== undefined && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    {props?.errors?.otherDetails?.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <TextArea
                  labelName="Specifications related to additional benefits/perks/entitlements"
                  // isOptional={true}
                  onChangeTextareaHandler={(event) =>
                    props.onChangeTextareaHandler(event, "specification")
                  }
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={props.specificationCharComplete}
                  value={props.jobfields.specification}
                />
                {props.jobfields.specification !== undefined && (
                  <label style={{ marginTop: "-2px", color: "red" }}>
                    {" "}
                    {props?.errors?.specification?.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <UploadFile
                label="Upload Document"
                selectedFilesCallBack={(file) =>{
                  setNewFile(true);
                  return props.selectedFilesCallBack(file)
                }
                }
              />
              {
                !newFile && (
              <aside>
                <a href={props.jobfields.doc_url?props.jobfields.doc_url:"" }><p>{props.jobfields.doc_url?props.jobfields.doc_url:"" }</p></a>
              </aside>
                )
              }
              
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default JobForm;
