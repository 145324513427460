import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { Row, Col, CardBody, Card, Alert, Container, Label, InputGroup, InputGroupAddon } from "reactstrap";
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import rightIcon from "../../assets/images/right.png"
import { changePassword } from '../../redux/actions/accountsetting';
import blankImg from '../../assets/images/face.png';
import phone_icon from "../../assets/images/phone_icon.png";
import email_icon from "../../assets/images/email_icon.png";
import company_img from "../../assets/images/company.png";
import { getOrganizationDetails } from '../../redux/actions/profile';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const CompnaySizes = [
    {
        value: 1,
        label: '0-10 employees',
        modifyName: 'Start up/ Small Enterprise'
    },
    {
        value: 2,
        label: '10-50 employees',
        modifyName: 'Mid sized'
    },
    {
        value: 3,
        label: '50-200+ employees',
        modifyName: 'Large scale/ Global'
    }
];

const AccountSetting = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');

    const [pwdElementtype, setPwdElementType] = useState('password');
    const [confirmPwdElementType, setConfirmPwdElementType] = useState('password');
    const [curr_pwdElementtype, setCurr_pwdElementtype] = useState('password');

    const [confirmpassErr, setConfirmPassErr] = useState('This field is required.');
    const [showRightIcon, setShowRightIcon] = useState(false);
    const [form, setForm] = useState(undefined);

    useEffect(() => {
        props.getOrganizationDetails();
    }, []);

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        //props.loginUser(values, props.history);
        console.log("set password data on submit ", password, confirmPassword, currentPassword);
        props.changePassword(currentPassword, password, confirmPassword);
        form.reset();
        setPassword(undefined);
        setShowRightIcon(false);

    }

    function handlePasswodType(type) {
        let newType = type === 'text' ? 'password' : 'text';
        setPwdElementType(newType);
    }

    function handleConfirmPasswodType(type) {
        let newType = type === 'text' ? 'password' : 'text';
        setConfirmPwdElementType(newType);
    }

    function handleCurrentPasswodType(type) {
        let newType = type === 'text' ? 'password' : 'text';
        setCurr_pwdElementtype(newType);
    }

    function onConfrmPasswordChange(target) {
        setConfirmPassword(target.value);
        if (target.value && password !== target.value) {
            setConfirmPassErr('Passwords do not match.');
        } else {
            setConfirmPassErr('This field is required.');
        }
    }


    function validateNumericUpperCase(value) {
        let regex = /(?=.*[A-Z])(?=.*[0-9])/;
        if (!value.match(regex)) {
            return false;
        }
        return true;
    }

    function onChangePassword(target) {
        setPassword(target.value);
        if (target.value && target.value.length >= "8" && validateNumericUpperCase(target.value)) {
            setShowRightIcon(true);
        } else {
            setShowRightIcon(false);
        }
    }

    function onChangeCurrentPassword(target) {
        setCurrentPassword(target.value);
    }

    function getModifyName(name) {
        let temModifyName = '';
        CompnaySizes.map((item) => {
            if (item.label === name) {
                temModifyName = item.modifyName
            }
        });
        return temModifyName
    }


    return (
        <React.Fragment>
            <div className="account-setting my-5 pt-sm-5">
                <Container fluid><Row>
                    <Col xl="12" className='pl-0 pr-0 mobile_padding'>
                        <div className="top-container">
                            <div className="ux-designer">
                                {" "}
                                {props.profile.name}
                            </div>
                            <div>
                                <img className="ml-4 shift-image rounded-circle avatar-md" src={props.profile.logo_url ? props.profile.logo_url : blankImg} alt="Header Avatar" />
                            </div>
                            <div className="d-flex justify-content-between company-info">
                                <div className="d-flex mx-5 px-4 green-text-color mobile_account_header">
                                    <div className='mr-3'>
                                        <div className='web_display'>
                                            <div className="f-14 mb-2 px-2 mob_display">
                                                <img src={email_icon} alt='email' />
                                                <span className='ml-2'>{props.profile.email ? props.profile.email : '--'}</span>
                                            </div>
                                            <div className='ml-2'>
                                                <img src={phone_icon} alt='email' />
                                                <span className='ml-2'>{props.profile.number ? props.profile.number : '--'}</span>
                                            </div>
                                        </div>

                                        <div className='web_display'>
                                            <div className="f-14 mb-2 px-2 mob_display mobile_top_spacing">
                                                {props.profile.location && <i className="fas fa-map-marker-alt mb-2 px-2"></i>}
                                                {props.profile.location && <span className="">{props.profile.location}</span>}
                                            </div>
                                            <div className='ml-2 mob_display'>
                                                {props.profile.size &&
                                                    <span className='ml-2 green-status-text mobile_no_left_margin'>{props.profile.size ? getModifyName(props.profile.size) : '--'}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <div className='d-flex align-items-start position-relative'>
                                        <span className="right-pencil-section">
                                            <i className="bx bx-pencil font-size-18 cursor-pointer"></i>
                                        </span>  
                                    </div> */}
                        </div>
                    </Col>
                </Row>
                    <Row className="mt-3 mb-2 justify-content-center">
                        <Col md={9} lg={9}>
                            <div className='mb-2 text-left'>Account Settings </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={9} lg={9}>
                            <Card className="overflow-hidden p-3">
                                <div className="pl-2">
                                    <Row>
                                        <Col>
                                            <p className="font-size-18 mb-0"> Change Password </p>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className='pb-0 pl-2 pr-2'>
                                    <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit} ref={c => { setForm(c) }}>
                                        {props.error && props.error ? <Alert color="danger">{props.error.message || props.error.reason}</Alert> : null}
                                        <AvGroup>
                                            <Label for="curr_password">Current Password</Label>
                                            <InputGroup>
                                                <AvInput name="curr_password" type={curr_pwdElementtype} placeholder="Enter Password" required
                                                    onChange={({ target }) => { onChangeCurrentPassword(target); }}
                                                    style={{ paddingRight: "2rem" }}

                                                />
                                                <InputGroupAddon onClick={() => handleCurrentPasswodType(curr_pwdElementtype)} addonType="append">
                                                    {curr_pwdElementtype === 'text' ? <i className="mdi mdi-eye-off-outline font-size-20"></i> :
                                                        <i className="mdi mdi-eye-outline font-size-20"></i>
                                                    }
                                                </InputGroupAddon>
                                                <AvFeedback>This field is required.</AvFeedback>
                                            </InputGroup>
                                        </AvGroup>

                                        <AvGroup>
                                            <Label for="password">New Password</Label>
                                            <InputGroup>
                                                <AvInput name="password" type={pwdElementtype} placeholder="Enter Password" required
                                                    onChange={({ target }) => { onChangePassword(target); }}
                                                    style={{ paddingRight: "2rem" }}

                                                />
                                                <InputGroupAddon onClick={() => handlePasswodType(pwdElementtype)} addonType="append">
                                                    {pwdElementtype === 'text' ? <i className="mdi mdi-eye-off-outline font-size-20"></i> :
                                                        <i className="mdi mdi-eye-outline font-size-20"></i>
                                                    }
                                                </InputGroupAddon>
                                                <AvFeedback>This field is required.</AvFeedback>
                                            </InputGroup>
                                        </AvGroup>

                                        <AvGroup className="font-weight-light pass-err-desc">
                                            <p className="mb-0 font-weight-200 roboto-light text-muted"> Password must be </p>
                                            {showRightIcon ?
                                                <ul className="list-unstyled">
                                                    <li> <img alt="no image1" src={rightIcon}></img> Minimum of 8 characters</li>
                                                    <li><img alt="no image2" src={rightIcon}></img>Atleast 1 Uppercase</li>
                                                    <li><img alt="no image3" src={rightIcon}></img>Atleast 1  Numeric</li>
                                                </ul>
                                                :
                                                <ul className="pl-3">
                                                    <li>Minimum of 8 characters</li>
                                                    <li>Atleast 1 Uppercase</li>
                                                    <li>Atleast 1  Numeric</li>
                                                </ul>
                                            }

                                        </AvGroup>

                                        <AvGroup>
                                            <Label for="cpassword">Confirm New Password</Label>
                                            <InputGroup>
                                                <AvInput name="cpassword" type={confirmPwdElementType} placeholder="Enter Confirm Password"
                                                    onChange={({ target }) => { onConfrmPasswordChange(target); }}
                                                    validate={{
                                                        required: { value: true },
                                                        match: { value: "password" }
                                                    }}
                                                    style={{ paddingRight: "2rem" }}

                                                />
                                                <InputGroupAddon onClick={() => handleConfirmPasswodType(confirmPwdElementType)} addonType="append">
                                                    {confirmPwdElementType === 'text' ? <i className="mdi mdi-eye-off-outline font-size-20"></i> :
                                                        <i className="mdi mdi-eye-outline font-size-20"></i>
                                                    }
                                                </InputGroupAddon>
                                                <AvFeedback>{confirmpassErr} </AvFeedback>
                                                {password && password === confirmPassword ? <div className="pw-match-success">Passwords match.</div> : ''}
                                            </InputGroup>
                                        </AvGroup>
                                        <div className="mt-3 text-right">
                                            <button className="btn bg-green waves-effect waves-light w-md" type="submit">Change Password</button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);
}

const mapStateToProps = (state) => {
    return {
        ...state.profile,
        ...state.setting,
        // error: state.setting.password ? state.setting.password.reason : null
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getOrganizationDetails, changePassword }, dispatch);
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSetting));
