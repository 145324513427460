import React, { useState, useEffect } from "react";
import { NavItem, NavLink, Card, CardBody, Row, Col } from "reactstrap";
import logo from "../../../assets/images/Zunavish_Logo_SVG.svg";
import JobForm from "./JobForm";
import classnames from "classnames";
import ApiService from "../../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { getOrganizationDetails } from "../../../redux/actions/profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Domains } from "../../../helpers/constant_variables";

import { Locations, EducationList } from "../../../helpers/constant_variables";
import s3apis from "../../../helpers/s3apis";
import { Link } from "react-router-dom";

const EmploymentTypes = [
  {
    value: 1,
    label: "Full-Time Employee",
  },
  {
    value: 2,
    label: "Part-Time Employee",
  },
  {
    value: 3,
    label: "Temporary Employee",
  },
  {
    value: 4,
    label: "Seasonal Employee",
  },
];

const DiversityList = [
  {
    value: 1,
    label: "Male",
  },
  {
    value: 2,
    label: "Female",
  },
  {
    value: 3,
    label: "NA",
  },
];

const MaxAgeList = [
  {
    value: 1,
    label: "20",
  },
  {
    value: 2,
    label: "30",
  },
  {
    value: 3,
    label: "40",
  },
  {
    value: 4,
    label: "50",
  },
];

const MinAgeList = [
  {
    value: 1,
    label: "18",
  },
  {
    value: 2,
    label: "21",
  },
  {
    value: 3,
    label: "30",
  },
  {
    value: 4,
    label: "40",
  },
];

const AboutYourself = (props) => {
  const wizardHeadersNames = [
    { name: "Job Details", key: "pd-width" },
    { name: "Select Assessment Tests (Coming Soon)", key: "ed-width" },
  ];

  const [wizardHeaders, setwizardHeaders] = useState([]);
  const [activeTab, setactiveTab] = useState(1);

  const [roleCharComplete, setRoleCharComplete] = useState(0);
  const [otherDetailsCharComplete, setOtherDetailsCharComplete] = useState(0);
  const [specificationCharComplete, setSpecificationCharComplete] = useState(0);
  const [uploadeddoc, setUploadedDoc] = useState();
  const [jobfields, setJobfields] = useState({
    roleandResponsibilty: "",
    otherDetails: "",
    specification: "",
    title: "",
    company: "",
    location: "",
    isRemoteLoc: false,
    locationDisabled: false,
    emp_type: "",
    skills_good: [],
    skills_must: [],
  });

  console.log(props);
  const [selectedFiles, setSelectedFiles] = useState("");

  const selectedFilesCallBack = (file) => {
    try {
      if (!file) {
        throw new Error("Select a file first!");
      }
      // const formData = new FormData();
      // formData.append("file", file[0]);
      // setSelectedFiles(formData);
      // ApiService.uploadDoc(formData).then((res) => {
      //     if (res && res.data) {
      //         setJobfields(
      //             {
      //                 ...jobfields,
      //                 doc_url: res.data.data.Location
      //             }
      //         );
      //     }
      // });
      if (file[0].size < 5242880) {
        setUploadedDoc(file[0]);
      } else {
        toastr.error("Select a file of size less than 5 MB", "", {
          timeOut: 2000,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeTextareaHandler = (event, type, t) => {
    if (event?.target?.value) {
      event.target.value = event?.target?.value?.trimStart();
    }
    if (
      type === "roles" ||
      type === "otherdetails" ||
      type === "specification"
    ) {
      // var charCount = event.target.value.length;
      event.target.value = event.target.value.trimStart();
      var regex = /\s+/gi;
      var WordCount =
        event.target.value === ""
          ? 0
          : event.target.value.trim().replace(regex, " ").split(" ").length;
    }
    switch (type) {
      case "roles":
        if (WordCount <= 500){
          setRoleCharComplete(WordCount);
          setJobfields({
            ...jobfields,
            roleandResponsibilty: event.target.value,
          });
        }
        break;
      case "otherdetails":
        if (WordCount <= 500){
          setOtherDetailsCharComplete(WordCount);
          setJobfields({ ...jobfields, otherDetails: event.target.value });
        } 
        break;
      case "specification":
        if (WordCount <= 500){
          setSpecificationCharComplete(WordCount);
          setJobfields({ ...jobfields, specification: event.target.value });
        } 
        break;
      case "title":
        event.target.value = event.target.value.replace(/[^a-zA-Z\s]/gi, "");

        setJobfields({ ...jobfields, title: event.target.value });
        break;
      case "company":
        setJobfields({ ...jobfields, company: event.target.value });
        break;
      case "location":
        setJobfields({
          ...jobfields,
          location: event.label,
          locationObj: event,
        });
        break;
      case "remote":
        if (event.target.checked) {
          setJobfields({
            ...jobfields,
            locationDisabled: true,
            location: "",
            locationObj: { label: "Select" },
            isRemoteLoc: event.target.checked,
          });
        } else {
          setJobfields({
            ...jobfields,
            locationDisabled: false,
            isRemoteLoc: event.target.checked,
          });
        }
        break;
      case "emptype":
        setJobfields({
          ...jobfields,
          emp_type: event.label,
          emp_type_obj: event,
        });
        break;
      case "diversity":
        setJobfields({
          ...jobfields,
          diversity: event.label,
          diversityObj: event,
        });
        break;
      case "domain":
        // const suggestions = event.skills.map((item) => {
        //     return {
        //       id: item.label,
        //       text: item.label
        //     }
        //   })
        setJobfields({
          ...jobfields,
          domain: event.label,
          domainObj: event,
          d_skills: [...event.skills],
          skills_must: [],
          skills_good: [],
        });
        break;
      case "maxage":
        setJobfields({ ...jobfields, max_age: event.label, maxAgeObj: event });
        break;
      case "minage":
        setJobfields({ ...jobfields, min_age: event.label, minAgeObj: event });
        break;
      case "minexp":
        setJobfields({ ...jobfields, minexp: event.target.value });
        break;
      case "maxexp":
        setJobfields({ ...jobfields, maxexp: event.target.value });
        break;
      case "edetails":
        setJobfields({
          ...jobfields,
          education_details: event.label,
          edetailsObj: event,
        });
        break;
      case "mustskills":
        const tempData = [...event];
        setJobfields({
          ...jobfields,
          skills_must: [...tempData],
        });
        break;
      case "goodskills":
        const tempData1 = [...event];
        setJobfields({
          ...jobfields,
          skills_good: [...tempData1],
        });
        break;
      default:
        break;
    }
  };

  const RemoveSkill = (index, type) => {
    if (index > -1) {
      if (type === "mustskills") {
        const tempData = { ...jobfields };
        tempData.skills_must && tempData.skills_must.splice(index, 1);
        setJobfields({
          ...tempData,
        });
      } else {
        const tempData = { ...jobfields };
        tempData.skills_good && tempData.skills_good.splice(index, 1);
        setJobfields({
          ...tempData,
        });
      }
    }
  };

  useEffect(() => {
    props.getOrganizationDetails();
  }, []);

  useEffect(() => {
    if (jobfields.domain) {
      const getDomainIndex = findWithAttr(Domains, "label", jobfields.domain);
      const results = Domains[getDomainIndex].skills.filter((person) => {
        if (jobfields.mustSkillVal) {
          return person.label.toLowerCase().includes(jobfields.mustSkillVal);
        } else if (jobfields.goodSkillVal) {
          return person.label.toLowerCase().includes(jobfields.goodSkillVal);
        } else {
          return Domains[getDomainIndex].skills;
        }
      });
      setJobfields({
        ...jobfields,
        d_skills: results,
      });
    }

    wizardHeadersNames.map((obj, index) => {
      if (activeTab === index + 1) {
        obj["active"] = "active";
        obj["key"] = wizardHeadersNames[index].key;
      } else {
        delete obj.active;
        delete obj.key;
      }
      if (activeTab === 2) {
        if (activeTab === 3)
          wizardHeadersNames[activeTab - 3]["solidImage"] = "solid-line-circle";
        wizardHeadersNames[activeTab - 2]["solidImage"] = "solid-line-circle";
        wizardHeadersNames[activeTab - 2]["lessWidthD"] = "less-width-d";
      }
    });
    setwizardHeaders([...wizardHeadersNames]);
    if (props.location && props.location.state) {
      let empIndex = findWithAttr(
        EmploymentTypes,
        "label",
        props.location.state.employment_type
      );
      let domianIndex = findWithAttr(
        Domains,
        "label",
        props.location.state.domain
      );
      let diversityIndex = findWithAttr(
        DiversityList,
        "label",
        props.location.state.diversity
      );
      let minIndex = findWithAttr(
        MinAgeList,
        "label",
        props.location.state.min_age && props.location.state.min_age.toString()
      );
      let maxIndex = findWithAttr(
        MaxAgeList,
        "label",
        props.location.state.max_age && props.location.state.max_age.toString()
      );
      let locationIndex = findWithAttr(
        Locations,
        "label",
        props.location.state.location
      );
      let ed_Index = findWithAttr(
        EducationList,
        "label",
        props.location.state.education_level
      );
      setJobfields({
        ...props.location.state,
        roleandResponsibilty: props.location.state.roles,
        otherDetails: props.location.state.job_details,
        specification: props.location.state.perks,
        emp_type_obj: EmploymentTypes[empIndex],
        domainObj: Domains[domianIndex],
        diversityObj: DiversityList[diversityIndex],
        minAgeObj: MinAgeList[minIndex],
        maxAgeObj: MaxAgeList[maxIndex],
        locationObj: Locations[locationIndex],
        isRemoteLoc: props.location.state.is_remote_loc,
        minexp: props.location.state.min_exp,
        maxexp: props.location.state.max_exp,
        edetailsObj: EducationList[ed_Index],
        locationDisabled: props.location.state.is_remote_loc,
        emp_type: EmploymentTypes[empIndex]?.label,
        // skills_must: [...props.location.state.skills_must],
        education_details: props.location.state.education_level,
      });
    }
  }, [activeTab]);

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  console.log(props.location.state);

  const PublishJob = () => {
    let obj = {
      ...jobfields,
      roles: jobfields.roleandResponsibilty,
      job_details: jobfields.otherDetails,
      perks: jobfields.specification,
      employment_type: jobfields.emp_type,
      status: "open",
      company: props.profile && props.profile.name,
      min_exp: jobfields.minexp,
      max_exp: jobfields.maxexp,
      education_level: jobfields.education_details,
      is_remote_loc: jobfields.isRemoteLoc,
      doc_url: jobfields.doc_url,
    };
    if (props.location && props.location.state) {
      obj.id = props.location.state.id;
      ApiService.updateJobStatus(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          console.log("update");
          console.log(res);
          if (uploadeddoc) {
            var ext = uploadeddoc.name.split(".").pop();
            s3apis.upload("document", ext, uploadeddoc, obj.id);
          }
          toastr.success("Job Published Successfully.", "", { timeOut: 2000 });
          props.history.push("/jobdashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    } else {
      ApiService.addJobPost(obj).then((res) => {
        console.log("add");
        console.log(res);
        var id = res.data.id;
        if (uploadeddoc) {
          var ext = uploadeddoc.name.split(".").pop();
          s3apis.upload("document", ext, uploadeddoc, id);
        }
        if (res && res.status === 200 && res.data.success) {
          props.history.push("/jobdashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    }
  };

  const SaveDraft = () => {
    let obj = {
      ...jobfields,
      roles: jobfields.roleandResponsibilty,
      job_details: jobfields.otherDetails,
      perks: jobfields.specification,
      employment_type: jobfields.emp_type,
      status: "incomplete",
      company: props.profile && props.profile.name,
      min_exp: jobfields.minexp,
      max_exp: jobfields.maxexp,
      education_level: jobfields.education_details,
      is_remote_loc: jobfields.isRemoteLoc,
      doc_url: jobfields.doc_url,
    };
    if (props.location && props.location.state) {
      obj.id = props.location.state.id;
      ApiService.updateJobStatus(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          if (uploadeddoc) {
            var ext = uploadeddoc.name.split(".").pop();
            s3apis.upload("document", ext, uploadeddoc, obj.id);
          }
          toastr.success("Job Updated Successfully.", "", { timeOut: 2000 });
          props.history.push("/jobdashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    } else {
      ApiService.addJobPost(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          var id = res.data.id;
          if (uploadeddoc) {
            var ext = uploadeddoc.name.split(".").pop();
            s3apis.upload("document", ext, uploadeddoc, id);
          }
          props.history.push("/jobdashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    }
  };

  const WizardHeaders = (item1, idx) => {
    return (
      <NavItem key={idx}>
        <NavLink className={`text-center ${item1.active ? item1.active : ""}`}>
          <span
            className={classnames(
              "step-number mr-1",
              { [`${item1.key}`]: item1.key },
              { [`${item1.lessWidthD}`]: item1.lessWidthD },
              { [`${item1.solidImage}`]: item1.solidImage }
            )}
          >
            {idx + 1}
          </span>
          {item1.name ? (
            <div className={classnames("font-size-14 mt-2")}>
              {" "}
              {item1.name}{" "}
            </div>
          ) : (
            ""
          )}
        </NavLink>
      </NavItem>
    );
  };
  const [errors, setErrors] = useState({});
  function checkname(name) {
    name = name?.trim();
    if (name === "" || !name) {
      return { message: "Should not be blank", valid: false };
    } else if (/^(?=.*[a-zA-Z]).+/.test(name)) {
      return { message: "", valid: true };
    } else {
      return { message: "Please enter alpha-numeric value", valid: false };
    }
  }
  function checkNumber(number) {
    console.log(number);
    if (number === "") {
      return {
        message: "Required Field",
        valid: false,
      };
    } else if (number >= 1 && number <= 100) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid year",
        valid: false,
      };
    }
  }
  useEffect(() => {
    setErrors({
      title: checkname(jobfields?.title),
      roleandResponsibilty: checkname(jobfields?.roleandResponsibilty),
      otherDetails: checkname(jobfields?.otherDetails),
      specification: checkname(jobfields?.specification),
      maxexp: checkNumber(jobfields?.maxexp),
      minexp: checkNumber(jobfields?.minexp),
      education:checkname(jobfields?.education_details),
      domain:checkname(jobfields?.domain),
      emptype:checkname(jobfields?.emp_type),
    });
  }, [jobfields]);

  return (
    <React.Fragment>
      <div className="page-content p-0 mt-0 job-assessment">
        <div className="logo-fixed">
          <Link to="/">
            <img src={logo} alt="logo"></img>
          </Link>
        </div>
        <div className="about-page-auth">
          <Row>
            <Col xs="12" xl="3" sm="3"></Col>
            <Col xs="12" xl="6" sm="6" className="text-center">
              <h5 className="mb-0 font-size-24">
                {props.location && props.location.state ? "Edit" : "Add"} Job
              </h5>
              <div
                id="basic-pills-wizard"
                className="twitter-bs-wizard job-wizard"
              >
                <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
                  {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
                </ul>
                <div className="mt-2">
                  Request you to include the following in your JD format
                </div>
                <Card className="mt-4">
                  <CardBody>
                    {activeTab === 1 ? (
                      <JobForm
                        errors={errors}
                        activeTab={activeTab}
                        jobfields={jobfields}
                        onChangeTextareaHandler={onChangeTextareaHandler}
                        roleCharComplete={roleCharComplete}
                        otherDetailsCharComplete={otherDetailsCharComplete}
                        specificationCharComplete={specificationCharComplete}
                        setRoleCharComplete={setRoleCharComplete}
                        setOtherDetailsCharComplete={setOtherDetailsCharComplete}
                        setSpecificationCharComplete={setSpecificationCharComplete}
                        RemoveSkill={RemoveSkill}
                        companyName={props.profile && props.profile.name}
                        selectedFilesCallBack={selectedFilesCallBack}
                        setJobfields={setJobfields}
                        findWithAttr={findWithAttr}
                      />
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xs="12" xl="3" sm="3"></Col>
          </Row>
          <div
            title="Ensure all required fields are filled!"
            className="job-footer text-right"
          >
            <button
              className="mobile_btn btn btn-outline-green waves-effect waves-light w-lg mr-2 mt-2"
              type="button"
              disabled={!jobfields.title ? true : false}
              onClick={() => SaveDraft()}
            >
              Save as draft
            </button>
            <button
              className="mobile_btn btn bg-green waves-effect waves-light w-lg mr-2 mt-2"
              type="button"
              disabled={
                !jobfields.title ||
                  !errors?.title?.valid ||
                  (jobfields.location
                    ? !jobfields.location
                    : !jobfields.isRemoteLoc) ||
                  !jobfields.domain ||
                  !jobfields.emp_type ||
                  jobfields.skills_must.length < 1 ||
                  !jobfields.minexp ||
                  !errors?.minexp?.valid ||
                  !jobfields.maxexp ||
                  !errors?.maxexp?.valid ||
                  parseInt(jobfields.minexp) > parseInt(jobfields.maxexp) ||
                  (jobfields.maxexp && jobfields.minexp?!(Number(jobfields.minexp)<Number(jobfields.maxexp)):false) ||
                  !jobfields.education_details ||
                  !jobfields.roleandResponsibilty ||
                  !errors?.roleandResponsibilty?.valid ||
                  !jobfields.otherDetails ||
                  !errors?.otherDetails?.valid ||
                  !jobfields.specification ||
                  !errors?.specification?.valid
                  ? true
                  : false
              }
              onClick={() => PublishJob()}
            >
              Publish without Assessment
            </button>
            {/* <button className="btn bg-green waves-effect waves-light w-lg mt-2 mr-2"
                            type="button"
                        >Proceed to Add Assessment</button> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrganizationDetails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutYourself);
