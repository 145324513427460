import api from "../../config/axios";
import {

  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  PASSWORD_SET_SUCCESS,
  FORGOT_PASSWORD,
  PASSWORD_RESET_SUCCESS,
  NEW_PAGE

} from "../auth";
export const setpassword = (password, confirmpassword, token, history) => async dispatch => {
  dispatch({
    type: PASSWORD_SET_SUCCESS,
  });

  try {
    const response = await api.post('api/client/setPassword',
      { password: password, confirmPassword: confirmpassword, token: token },
    );
    if (response.status === 200) {
      if (response.data.success) {
        localStorage.setItem('token', response.data.authToken)
        history.push('/dashboard');
      } else {
        dispatch({
          type: API_ERROR,
          payload: response.data.reason,

        });
      }
    }
    else {
      dispatch({
        type: API_ERROR,
        payload: response.data.message,

      });
    }
  }
  catch (err) {
    dispatch({
      type: API_ERROR,
      payload: err.response.data.message,

    });
  }

}
export const ResetPassword = (password, confirmpassword, token, history) => async dispatch => {
  dispatch({
    type: PASSWORD_RESET_SUCCESS,
  });

  try {
    const response = await api.post('api/users/password/reset/check_password/',
      { password: password, confirmPassword: confirmpassword, token: token, role: "client", },
    );
    if (response.status === 200) {
      if (response.data.success) {
        localStorage.setItem('token', response.data.authToken)
        history.push('/dashboard');
      } else {
        dispatch({
          type: API_ERROR,
        });
      }
      // history.push('/aboutyourself');
    }
    else {
      dispatch({
        type: API_ERROR,
      });
    }
  }
  catch {
    dispatch({
      type: API_ERROR,
    });
  }

}
export const forgotpassword = (email, history) => async dispatch => {
  localStorage.setItem('token', "");
  dispatch({
    type: FORGOT_PASSWORD,
  });
  const response = await api.post('api/client/reset',
    { email, role: "client" },
  ).then((response) => {
    dispatch({
      type: FORGOT_PASSWORD,
      payload: email,
    });
    if (response.status === 200 && response.data.success === "true") {
      history.push("/passwordreset")
    }
    else {
      dispatch({
        type: API_ERROR,
        payload: response.message,
      });
    }
  })
    .catch(err => {

      if (err.response) {
        dispatch({
          type: API_ERROR,
          payload: err.response.data.message,
        });
      }
      else {
        dispatch({
          type: API_ERROR,
          payload: "Unable to reach to Servers"
        });
      }

    })

}
export const newPage = () => dispatch => {
  dispatch({
    type: NEW_PAGE,
  });
}
export const loginUser = (email, password, history) => async dispatch => {

  dispatch({
    type: LOGIN_USER,
  });



  api.post('api/client/login',
    {
      email: email,
      password: password,
      role: 'client'
    }, {
    headers: {
      'content-type': 'application/json',
      'Cache-Control': "no-cache"
    }
  }).then((res) => {
    if (res.status === 200 && res.data.success === true) {
      dispatch({
        type: LOGIN_SUCCESS,
      });
      localStorage.setItem('token', res.data.authToken);
      history.push("/dashboard")
    }
    else {
      dispatch({
        type: API_ERROR,
        payload: res.data.message
      });
    }

  })
    .catch(err => {
      dispatch({
        type: API_ERROR,
        payload: err.response.data.message,
      });
      if (err.response) {
        if (err.response.status === 404) {
          console.log('Error: Page Not Found');
        }
        else {
          console.log(err.response.data.reason)
        }
      }
      else if (err.request) {
        // Request was made but no response
        console.log(err.request);
      }
      else {
        console.log(err.message);
      }
    });



  //Add async action
}


export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  }
}

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history }
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  }
}

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error
  }
}
